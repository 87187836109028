import React from 'react';
import { LoadBlock } from '@bwoty-web/styleguide';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import styled from 'styled-components';

const LoadingHeader = styled(LoadBlock)`
  height: 24px;
  margin-bottom: 8px;
  width: 200px;
`;

const LoadingRow = styled(LoadBlock)`
  height: 16px;

  &:not(:last-of-type) {
    margin-bottom: 7px;
  }

  @media (max-width: ${deviceWidths.tabletMax}) {
    width: 100%;
  }

  @media (min-width: ${deviceWidths.tablet}) {
    flex: 1 1 auto;
  }
`;

const PriceDetailsInfoLoader = () => {
  return (
    <>
      <LoadingHeader />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
    </>
  );
};

export default PriceDetailsInfoLoader;
