import { useMemo } from 'react';
import { useDefaultSearchQuery } from './useDefaultSearchQuery';

const DEFAULT_ROOM_AGES = '42,42';
const validPaxes = ['1_0', '1_1', '1_2', '2_0', '2_1', '2_2', '2_2', '2_3', '3_0', '3_1', '4_0'];

export const getRoomAgesString = (searchParams, defaults = {}) => {
  return searchParams?.QueryRoomAges || defaults.roomAges || DEFAULT_ROOM_AGES;
};

export const getRoomAges = (roomAgesString) => {
  return (
    roomAgesString
      .replace('|', '')
      .split(',')
      .map((age) => parseInt(age, 10))
      // eslint-disable-next-line no-restricted-globals
      .filter((a) => (a || a === 0) && !isNaN(a))
  );
};

export const getPax = (roomAges) => {
  const nrOfAdults = roomAges.filter((aa) => aa === 42).length;
  const nrOfChildren = roomAges.filter((aa) => aa < 42).length;
  return `${nrOfAdults}_${nrOfChildren}`;
};

export const isPaxValid = (pax) => {
  return validPaxes.indexOf(pax) !== -1;
};

export const usePax = (searchParams) => {
  const defaults = useDefaultSearchQuery();

  const roomAgesString = useMemo(
    () => getRoomAgesString(searchParams, defaults),
    [defaults, searchParams]
  );
  const roomAges = useMemo(() => getRoomAges(roomAgesString), [roomAgesString]);
  const pax = useMemo(() => getPax(roomAges), [roomAges]);
  const paxIsValid = useMemo(() => isPaxValid(pax), [pax]);

  return {
    roomAges,
    pax: pax || '2_0',
    paxIsValid,
  };
};
