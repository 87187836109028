const transformKey = (input, isChild) => {
  const result = input
    .replace('_t', '')
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .replace(/\bac\b/, 'AC')
    .replace(/wi\s*fi/gi, 'WiFi');
  let output = result.charAt(0).toUpperCase() + result.slice(1);

  if (output === 'Geographical') {
    output = isChild ? 'Resort' : 'Area';
  }
  return output;
};

export default transformKey;
