import { fitBounds } from 'google-map-react';

export default function getCenterZoom(hotels) {
  if (!hotels.length || hotels[0] == null) {
    return {
      // Om ospecifierat boende, sätt Gibraltar sunds koordinater och zooma ut...
      center: {
        lng: 5.353,
        lat: 36.143,
      },
      zoom: 1,
    };
  }

  if (hotels.length === 1) {
    return {
      center: {
        lat: hotels[0].hotel.content.position.latitude,
        lng: hotels[0].hotel.content.position.longitude,
      },
      zoom: 14,
    };
  }

  const bounds = hotels.reduce(
    (prev, curr) => {
      return {
        nw: {
          lat: Math.max(prev.nw.lat, curr.hotel.content.position.latitude),
          lng: Math.min(prev.nw.lng, curr.hotel.content.position.longitude),
        },
        se: {
          lat: Math.min(prev.se.lat, curr.hotel.content.position.latitude),
          lng: Math.max(prev.se.lng, curr.hotel.content.position.longitude),
        },
      };
    },
    {
      nw: {
        lat: hotels[0].hotel.content.position.latitude,
        lng: hotels[0].hotel.content.position.longitude,
      },
      se: {
        lat: hotels[0].hotel.content.position.latitude,
        lng: hotels[0].hotel.content.position.longitude,
      },
    }
  );

  const lng = Math.abs(bounds.se.lng - bounds.nw.lng);
  const lat = Math.abs(bounds.nw.lat - bounds.se.lat);
  bounds.nw.lng -= 0.1 * lng;
  bounds.nw.lat += 0.1 * lat;
  bounds.se.lng += 0.1 * lng;
  bounds.se.lat -= 0.1 * lat;

  const size = {
    width: Math.min(window.innerWidth, 975), // Map width in pixels
    height: Math.min(window.innerHeight, 707), // Map height in pixels
  };

  return fitBounds(bounds, size);
}
