import { useMemo } from 'react';

const guestRatingMapping = {};
for (let i = 30, j = 0; i < 50; i++, j++) {
  guestRatingMapping[i] = j;
}

export const getGuestRating = (searchParams) => {
  const { guestRatingFrom, guestRatingTo } = searchParams;
  if (!guestRatingFrom && !guestRatingTo) return [];

  const from = parseInt(guestRatingFrom || '30', 10);
  const to = parseInt(guestRatingTo || '50', 10) - 1;

  if (from === 30 && to === 49) return [];

  const fromMapped = guestRatingMapping[from] || guestRatingMapping[30];
  const toMapped = guestRatingMapping[to] || guestRatingMapping[49];
  const groups = [];
  for (let i = fromMapped; i <= toMapped; i++) {
    groups.push(`g${i}`);
  }
  return groups;
};

export const useGuestRating = (searchParams) => {
  const guestRating = useMemo(() => getGuestRating(searchParams), [searchParams]);

  return {
    guestRating,
  };
};
