const translation = {
  vs: 1,
  1: 1,
  vn: 3,
  3: 3,
  sd: 11,
  11: 11,
  tf: 15,
  15: 15,
  ds: 18,
  18: 18,
};

export default function convertMucd(mucdOrSiteId) {
  if (typeof mucdOrSiteId === 'number' || typeof mucdOrSiteId !== 'string') {
    return mucdOrSiteId;
  }

  return translation[mucdOrSiteId.toLowerCase()] || mucdOrSiteId;
}
