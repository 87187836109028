export const getSearchEvent = (
  meta,
  departures,
  paxStr,
  travelHotelFinderHits,
  travelType,
  query,
  hitsWithPrice,
  intervalOffset
) => {
  const adults = parseInt(paxStr.split('_')[0], 10);
  const children = parseInt(paxStr.split('_')[1], 10);

  const {
    QueryDepDate: queryDepDate,
    QueryDepMonths: queryDepMonths,
    QueryDur: queryDuration,
  } = query;
  // queryDepMonths YYYYMM,YYYYMM if several months are chosen
  const depMonths = queryDepMonths ? queryDepMonths.split(',') : [''];
  const depDate = queryDepDate
    ? queryDepDate.split('-')
    : ['NoneChosen', 'NoneChosen', 'NoneChosen'];

  const depYear = depDate[0] ? depDate[0] : depMonths[0].slice(0, 4);
  // Always pick first available departure month
  const depMonth = depDate[1] ? depDate[1] : depMonths[0].slice(-2);
  // Always pick first day of month when query is whole month
  let depDay;

  if (depDate[2]) {
    // eslint-disable-next-line prefer-destructuring
    depDay = depDate[2];
  } else if (depMonths[0]) {
    depDay = '01';
  }

  const travelDuration = queryDuration || 8;

  return {
    event: 'Search',
    travelAction: 'Search',
    travelDepartureCode: departures.join(','),
    travelDestinationCode: meta?.destinationCode || 'NoneChosen',
    travelCountryCode: meta?.countryCode,
    travelResortCode: meta?.resortCode || 'NoneChosen',
    travelAdults: adults,
    travelChildren: children,
    travelFlexibleDate: intervalOffset,
    travelHotelFinderHits,
    travelHits: hitsWithPrice,
    travelPax: adults + children,
    travelType,
    travelDepartureYear: depYear || 'NoneChosen',
    travelDepartureMonth: depMonth || 'NoneChosen',
    travelDepartureDay: depDay || 'NoneChosen',
    travelDuration,
  };
};

export const getFilterEvent = (
  travelHotelFinderHits,
  searchEvent,
  travelFilterSet,
  hitsWithPrice
) => {
  return {
    ...searchEvent,
    event: 'Filter',
    travelAction: 'Filter',
    travelHotelFinderHits,
    travelHits: hitsWithPrice,
    travelFilterSet,
  };
};

export const getSortEvent = (value) => {
  return {
    event: 'hotelfinderlistSort',
    eventCategory: 'Hotel Finder',
    eventAction: 'Click sortOrder',
    eventLabel: value,
  };
};

export const getTravelType = (alias) => {
  const travelType = {
    hotelfinder: 'CharterPackage',
    hotelfindercity: 'IndependentPackage',
    hotelfindercityandsun: 'CharterPackage',
    hotelfinderarea: 'CharterPackage',
    hotelfindercountry: 'CharterPackage',
    hotelfinderworld: 'CharterPackage',
    hotelfinderregion: 'CharterPackage',
  };
  return travelType[alias];
};

export const getTravelFilterSet = (alias) => {
  const travelType = {
    hotelfinder: 'HotelFinder SunAndBath',
    hotelfindercity: 'HotelFinder City',
    hotelfindercityandsun: 'HotelFinder SunAndBath and City',
    hotelfinderarea: 'HotelFinder Area',
    hotelfindercountry: 'HotelFinder Country',
    hotelfinderworld: 'HotelFinder World',
    hotelfinderregion: 'HotelFinder Region',
  };
  return travelType[alias];
};

export const getTravelHotelFinderHits = (hotels) => {
  let count = 0;
  Object.keys(hotels).forEach((key) => {
    if (hotels?.[key]?.hotel?.priceOffer) {
      count++;
    }
  });
  return count;
};

const getHotelMeta = (hotelEntity, selectedDeparture, meta) => {
  const result = {
    departureCode: selectedDeparture?.[0]?.key,
    destinationCode: meta?.destinationCode,
    hotelCode: hotelEntity?.hotel?.content?.parts?.[0]?.codes?.[0]?.code,
  };

  if (hotelEntity.priceSpecificationUrl && hotelEntity.priceSpecificationUrl !== '') {
    const [
      ages,
      classCode,
      departureCode,
      dateStr,
      destinationCode,
      duration,
      mu,
      serialNumber,
      hotelCode,
      roomType,
      roomCount,
    ] = (hotelEntity.priceSpecificationUrl || '').split('/'); // eslint-disable-line
    result.departureCode = departureCode;
    result.destinationCode = destinationCode;
    result.hotelCode = `${destinationCode}${hotelCode}`;
  }

  return result;
};

export const getProductImpression = (
  newIds,
  hotelIds,
  entities,
  travelType,
  selectedDeparture,
  meta
) => {
  const idsToUse = newIds || hotelIds;
  const offset = newIds ? (hotelIds || []).length - newIds.length : 0;
  return {
    event: 'productImpression',
    ecommerce: {
      impressions: idsToUse.map((id, index) => {
        const entity = entities[id];
        const { isIndependent } = entity;
        const category = isIndependent ? 'IndependentPackage' : travelType;
        const hotelMeta = getHotelMeta(entity, selectedDeparture, meta);
        return {
          name: `${hotelMeta.departureCode} - ${hotelMeta.destinationCode}`,
          id: hotelMeta.hotelCode,
          price: entity?.hotel?.priceOffer?.totalPrice || '',
          brand: entity?.hotel?.mainCategory?.name || 'Other',
          category,
          variant: 'VITS',
          list: 'Hotel Finder List',
          position: index + offset,
        };
      }),
    },
  };
};
