import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SlideIn, PopUpTablet } from '@bwoty-web/styleguide';
import { useLocationContext } from '../../context/Location';
import { useHotelsContext } from '../../context/Hotels';
import FilterInput from './FilterInput';
import FilterFooter from './FilterFooterContainer';
import FilterTags from './FilterTags';
import { useInitialState } from '../../hooks/useInitialState';

const StyledSlideIn = ({ children, ...rest }) => {
  const StyledSlideInWrapper = styled.div`
    padding-bottom: 120px;
  `;
  return (
    <SlideIn {...rest}>
      <StyledSlideInWrapper>{children}</StyledSlideInWrapper>
    </SlideIn>
  );
};

const StyledPopUpTablet = styled(PopUpTablet)`
  @media (max-width: 974px) {
    display: none;
  }
  overflow-y: unset;
  padding-bottom: 120px;
  div {
    overflow-y: unset;
  }
`;

const FilterContainer = () => {
  const location = useLocationContext();
  const { facetCounts, filteredHotelCount, tripPrice, refetchHotels } = useHotelsContext();
  const [isWideScreen, setIsWideScreen] = useState(true);
  const { umbracoTexts } = useInitialState();

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth >= 975);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onClose = useCallback(() => {
    location.actions.setSearchParam('filter', '');
  }, [location.actions]);

  const isClosed = location.searchParams.filter !== 'true';
  const handleIsOpen = useCallback(() => {
    if (isWideScreen) return false;
    return isClosed;
  }, [isClosed, isWideScreen]);

  const Filters = isWideScreen ? StyledPopUpTablet : StyledSlideIn;

  return (
    <Filters
      close={onClose}
      closed={handleIsOpen()}
      header={umbracoTexts?.labels?.filter}
      footer={
        <FilterFooter
          location={location}
          filteredHotelCount={filteredHotelCount}
          refetchHotels={refetchHotels}
        />
      }
    >
      <FilterTags searchParams={location.searchParams} refetchHotels={refetchHotels} />
      {Object.keys(umbracoTexts?.filters || {}).map((key) => (
        <FilterInput
          key={key}
          facetKey={key}
          data={umbracoTexts?.filters[key]}
          facetCounts={facetCounts}
          searchParams={location.searchParams}
          tripPrice={tripPrice}
          refetchHotels={refetchHotels}
        />
      ))}
    </Filters>
  );
};

export default FilterContainer;
