import gql from 'graphql-tag';

export const detailsQuery = gql`
  query PriceDetailsQuery($key: String!, $fetchTexts: Boolean!, $useNewPriceDetails: Boolean!) {
    attributeCollection: attributeCollectionsHugin(collections: ["price-spec"])
      @include(if: $fetchTexts)
    team1PriceDetailsFromFilter(key: $key) @include(if: $useNewPriceDetails) {
      numberOfAdults
      numberOfChildren
      hotel
      rooms {
        amount
        name
      }
      meal
      departureName
      departureTime {
        long
        time
      }
      duration
      totalPrice
      components {
        description
        amount
        price
        totalPrice
        currencyCode
        discount
      }
      currencyCode
    }
    hotelFinderPriceDetails(key: $key) @skip(if: $useNewPriceDetails) {
      numberOfAdults
      numberOfChildren
      hotel
      rooms {
        amount
        name
      }
      meal
      departureName
      departureTime {
        long
        time
      }
      duration
      totalPrice
      components {
        description
        amount
        price
        totalPrice
        currencyCode
        discount
      }
      currencyCode
    }
  }
`;
