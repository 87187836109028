import { distanceBeachMap, distanceCenterFiltermap, distanceMetroFilterMap } from '../utils/filterDictionaries';
import { getGuestRating } from './useGuestRating';
import { getPriceFilter } from './usePriceFilter';

export const getMergedFilters = (searchParams) => {
  const filterKeys = Object.keys(searchParams.filters || {}).filter(f => searchParams.filters[f]);

  const toCenter = parseInt(searchParams.distanceCenterTo, 10);
  const distanceCenter = [distanceCenterFiltermap[toCenter]].filter(x => x);

  const toBeach = parseInt(searchParams.distanceBeachTo, 10);
  const distanceBeach = [distanceBeachMap[toBeach]].filter(x => x);

  const toMetro = parseInt(searchParams.distanceMetroTo, 10);
  const distanceMetroo = [distanceMetroFilterMap[toMetro]].filter(x => x);

  return [
    ...getGuestRating(searchParams),
    ...getPriceFilter(searchParams),
    ...filterKeys,
    ...distanceCenter,
    ...distanceBeach,
    ...distanceMetroo,
  ].filter(x => x).join(',') || '-';
};
