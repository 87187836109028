/**
 * Shared styles used by *HotelHit components
 */
import styled from 'styled-components';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';

export const HotelHitWrap = styled.div`
  &:not(:first-of-type) {
    @media (max-width: ${deviceWidths.desktopMax}) {
      margin-top: 12px;
    }

    @media (min-width: ${deviceWidths.desktop}) {
      margin-top: 20px;
    }
  }
`;
