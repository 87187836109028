/* eslint-disable max-len */

export const USER_INTERFACE_EQUALIZER_ROTATED = 'M28.71,4.25h3.23V6.38H28.71a5.32,5.32,0,0,1-10.43,0H.06V4.25H18.23A5.31,5.31,0,0,1,23.44,0,5.38,5.38,0,0,1,28.71,4.25ZM20.25,5.32a3.19,3.19,0,1,0,3.19-3.19A3.24,3.24,0,0,0,20.25,5.32Zm-6.48,9.62H31.94v2.12H13.77a5.32,5.32,0,0,1-10.43,0H.06V14.94H3.29a5.35,5.35,0,0,1,10.48,0ZM5.32,16a3.19,3.19,0,1,0,3.19-3.19A3.21,3.21,0,0,0,5.32,16Zm19.09,9.57h7.48v2.18H24.41a5.33,5.33,0,0,1-10.44,0H.06V25.62H14a5.33,5.33,0,0,1,5.22-4.25A5.2,5.2,0,0,1,24.41,25.57ZM16,26.68a3.19,3.19,0,1,0,3.19-3.19A3.17,3.17,0,0,0,16,26.68Z';

export const TIME_LOCATION_MAP_ROUTE = 'M18,12V23a1,1,0,0,1-2,0V12a1,1,0,0,1,2,0ZM32,6V29l-8-4-7,4L8,27,0,31V7L8,3l9,4,7-6ZM30,7,24,3,17,9,8,5,2,8V28l6-3,9,2,7-4,6,3ZM9,8A1,1,0,0,0,8,9V22a1,1,0,0,0,2,0V9A1,1,0,0,0,9,8ZM24,20a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z';

export const USER_INTERFACE_LIST = 'M6.86,26.29V24H32v2.29Zm0-11.43H32v2.29H6.86Zm0-9.14H32V8H6.86ZM0,24H2.29v2.29H0Zm0-9.14H2.29v2.29H0ZM0,5.72H2.29V8H0Z';

export const USER_INTERFACE_CIRCLE_PLUS_ADD = 'M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2.13A13.87,13.87,0,1,0,29.87,16,13.87,13.87,0,0,0,16,2.13ZM22.35,17H17v5.31a1,1,0,1,1-2.08,0V17H9.65a1,1,0,1,1,0-2.08H15V9.65a1,1,0,1,1,2.08,0V15h5.31a1,1,0,1,1,0,2.08Z';

export const USER_INTERFACE_CIRCLE_MINUS_DELETE = 'M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2.13A13.87,13.87,0,1,0,29.87,16,13.87,13.87,0,0,0,16,2.13ZM22.35,17H9.65a1,1,0,1,1,0-2.08h12.7a1,1,0,1,1,0,2.08Z';
