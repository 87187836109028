import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('caught an error', error, info);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      if (process.env.NODE_ENV !== 'production') {
        return <p style={{ color: 'red' }}>Something went wrong.</p>;
      }
      return null;
    }
    return children;
  }
}
