import { parsePriceSpecificationUrl } from './parsePriceSpecificationUrl';

// Fixes special dynamic departures
const specialDepartures = {
  STO: 'ARN',
};

const getReturnDate = (departureDate, duration) => {
  try {
    const tempDate = new Date(departureDate);
    const dur = parseInt(duration, 10) - 1; // Remove 1 since a week duration is sent as 8.
    tempDate.setDate(tempDate.getDate() + dur);

    return `${tempDate.getFullYear()}${`0${tempDate.getMonth() + 1}`.slice(
      -2,
    )}${`0${tempDate.getDate()}`.slice(-2)}`;
  } catch (_) {
    return (departureDate || '').replace(/[^\d]/g, '');
  }
};

export function createUrl({
  dpBaseUrl,
  charterBaseUrl,
  isIndependent,
  query,
  mucd,
  totalPrice,
  priceSpecificationUrl,
  resortCaId,
  countryCaId,
  hotelCaId,
  departure,
  paxAges,
}) {
  const baseUrl = isIndependent ? dpBaseUrl : charterBaseUrl;
  const { departureCode, dateStr, destinationCode, duration, hotelCode, roomType } =
    parsePriceSpecificationUrl(priceSpecificationUrl);

  const roomAges = query.QueryRoomAges;
  const queryAges = (roomAges || paxAges.toString() || '42,42').replace('|', '');

  const queryDateStr = (dateStr || '').replace(/[^\d]/g, ''); // YYYY-MM-DD --> YYYYMMDD
  const depObj = departure.find(
    (dep) => dep.key === (specialDepartures[departureCode] || departureCode),
  ) || { caId: -1 };

  const queryArray = [
    `QueryDepID=${depObj.caId}`,
    `QueryResID=${resortCaId || -1}`,
    `ItemId=${hotelCaId}`,
    `HotelId=${hotelCaId}`,
    `QueryDepDate=${queryDateStr}`,
    `QueryAges=${queryAges}`,
  ];

  if (isIndependent) {
    const returnDate = getReturnDate(dateStr, duration);
    queryArray.push(`QueryRoomAges=${queryAges}`);
    queryArray.push('QueryUnits=1');
    queryArray.push(`QueryRetDate=${returnDate}`);
    queryArray.push(`QueryChkInDate=${queryDateStr}`);
    queryArray.push(`QueryChkOutDate=${returnDate}`);
    queryArray.push('QueryDur=-1');
    queryArray.push('QueryType=IndependentPackage');
    queryArray.push(`qf=flowDynamic`);
  } else {
    queryArray.push('UseBookingFlow=true');
    queryArray.push(`QueryCtryID=${countryCaId || -1}`);
    queryArray.push(`QueryDur=${duration}`);
    queryArray.push(`mucd=${mucd}`);
    queryArray.push(`RoomKey=${hotelCode}_${roomType}`);
    queryArray.push(`HotelKey=${encodeURIComponent(hotelCode)}`);
    queryArray.push(`DestinationCode=${destinationCode}`);
    queryArray.push(`qf=flowCharter`);
    if (totalPrice) {
      queryArray.push(`price=${totalPrice}`);
    }
  }

  return `${baseUrl}?${queryArray.join('&')}`;
}

export default (hotelObject, urls, query, mucd, departure, paxAges) => {
  const isIndependent = !!hotelObject?.isIndependent;
  // const baseUrl = isIndependent ? urls.dpHotelUrl : urls.charterHotelUrl;

  const { departureCode, hotelCode, roomType, duration, destinationCode } =
    parsePriceSpecificationUrl(hotelObject.priceSpecificationUrl || '');

  const dateStr = hotelObject?.hotel.priceOffer.departureDate.split(' ')[0];

  const roomAges = query.QueryRoomAges;

  const queryAges = (roomAges || paxAges || '42,42').replace('|', '');
  const queryDateStr = (dateStr || '').replace(/[^\d]/g, ''); // YYYY-MM-DD --> YYYYMMDD
  const depObj = departure.find(
    (dep) => dep.key === (specialDepartures[departureCode] || departureCode),
  ) || { caId: -1 };

  const queryArray = [
    `QueryDepID=${depObj.caId}`,
    `QueryResID=${hotelObject?.hotel?.content?.geographical?.resort?.caId || -1}`,
    `ItemId=${hotelObject?.hotel?.content.caId}`,
    `HotelId=${hotelObject?.hotel?.content?.caId}`,
    `QueryDepDate=${queryDateStr}`,
    `QueryAges=${queryAges}`,
  ];

  if (isIndependent) {
    const returnDate = getReturnDate(dateStr, duration);
    queryArray.push(`QueryRoomAges=${queryAges}`);
    queryArray.push('QueryUnits=1');
    queryArray.push(`QueryRetDate=${returnDate}`);
    queryArray.push(`QueryChkInDate=${queryDateStr}`);
    queryArray.push(`QueryChkOutDate=${returnDate}`);
    queryArray.push('QueryDur=-1');
    queryArray.push('QueryType=IndependentPackage');
    queryArray.push(`qf=flowDynamic`);
  } else {
    queryArray.push('UseBookingFlow=true');
    queryArray.push(
      `QueryCtryID=${hotelObject?.hotel?.content?.geographical?.country?.caId || -1}`,
    );
    queryArray.push(`QueryDur=${duration}`);
    queryArray.push(`mucd=${mucd}`);
    queryArray.push(`RoomKey=${hotelCode}_${roomType}`);
    queryArray.push(`HotelKey=${encodeURIComponent(hotelCode)}`);
    queryArray.push(`DestinationCode=${destinationCode}`);
    queryArray.push(`qf=flowCharter`);
    if (hotelObject?.hotel.priceOffer) {
      queryArray.push(`price=${hotelObject?.hotel.priceOffer.totalPrice}`);
    }
  }

  return `${hotelObject?.hotel?.content?.url}?${queryArray.join('&')}`;
};
