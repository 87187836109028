import React from 'react';
import styled from 'styled-components';
import { colorAliases } from '@bwoty-web/styleguide/variables/colors';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import HotelListContainer from './HotelListContainer';
import MessageContainer from './messages/MessageContainer';
import FilterContainer from './filter/FilterContainer';
import SortBarContainer from './SortBarContainer';
import SearchContainer from './search/SearchContainer';

const RootContainer = styled.div`
  background: ${colorAliases.background};
  box-sizing: border-box;
  clear: both;
  margin: auto;
  max-width: 975px;
  padding-bottom: 100px;
  width: 100%;
  position: relative;

  & * {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterPanelContainer = styled.div`
  width: 0;
  z-index: 1;

  @media (min-width: ${deviceWidths.desktop}) {
    width: 234px;
    align-self: flex-start;
  }
`;

const MainPanelContainer = styled.div`
  flex: 1 1 0%;
  width: 100%;

  @media (min-width: ${deviceWidths.desktop}) {
    padding-left: 20px;
  }
`;

const Main = () => {
  return (
    <RootContainer>
      <Container>
        <SearchContainer />
        <SortBarContainer />
        <MessageContainer />
        <ContentContainer>
          <FilterPanelContainer>
            <FilterContainer />
          </FilterPanelContainer>
          <MainPanelContainer>
            <HotelListContainer />
          </MainPanelContainer>
        </ContentContainer>
      </Container>
    </RootContainer>
  );
};

export default Main;
