import React from 'react';
import { DistanceSlider } from '@bwoty-web/styleguide';
import SliderWrapper from './SliderWrapper';
import Heading from './Heading';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

const FilterBeach = (props) => {
  const { label, heading, searchParams, refetchHotels } = props;

  const hidden = searchParams.filter === 'true';

  const handleChangeComplete = (distanceBeachTo) => {
    const distance = parseInt(distanceBeachTo, 10) === 10000 ? '' : distanceBeachTo.toString();
    refetchHotels({
      newSearchParams: { distanceBeachTo: distance },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        params: { travelFilterCategory: 'Distance', travelFilterValue: 'Beach' },
      },
    });
  };

  const { distanceBeachTo: distance } = searchParams;
  const distanceBeachTo = parseInt(distance, 10);

  return (
    <div>
      {heading && <Heading>{heading}</Heading>}
      <SliderWrapper>
        <DistanceSlider
          onChangeComplete={handleChangeComplete}
          uniqueId="distance_beach"
          value={distanceBeachTo || DistanceSlider.maxValue}
          valueLabel={label || 'Up to'}
          hidden={hidden}
        />
      </SliderWrapper>
    </div>
  );
};

FilterBeach.defaultProps = defaultProps;

export default FilterBeach;
