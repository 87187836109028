import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  USER_INTERFACE_ARROW_04_DOWN,
  USER_INTERFACE_ARROW_04_UP,
} from '@bwoty-web/styleguide';
import { colors } from '@bwoty-web/styleguide/variables/colors';
import * as S from './style';

const StyledIcon = styled(Icon)`
  position: relative;
  top: -3px;
  width: 16px;
  fill: ${colors.almostblack};
`;

export const SelectedValue = ({ label, children, isOpen }) => {
  return (
    <S.SelectedWrapper>
      <S.SelectedContent>
        <S.SelectedLabel>{label}</S.SelectedLabel>
        <S.SelectedValue>
          <S.SelectedValueText>{children}</S.SelectedValueText>
          <StyledIcon path={isOpen ? USER_INTERFACE_ARROW_04_UP : USER_INTERFACE_ARROW_04_DOWN} />
        </S.SelectedValue>
      </S.SelectedContent>
    </S.SelectedWrapper>
  );
};
