import React from 'react';
import CheckboxWithInfo from './CheckboxWithInfo';
import Heading from './Heading';
import { useInitialState } from '../../hooks/useInitialState';

const defaultProps = {
  prefix: '',
};

const FilterDistricts = (props) => {
  const { heading, prefix, counts, searchParams, refetchHotels } = props;

  const { districts } = useInitialState();
  const { filters = {} } = searchParams;

  if (!Array.isArray(districts) || districts.length < 1) {
    return null;
  }

  const handleChange = (event, key) => {
    refetchHotels({
      newSearchParams: {
        filters: {
          [key]: !!counts?.[key] && event.target.checked,
        },
      },
    });
  };

  return (
    <div>
      <Heading>{heading}</Heading>
      {districts.map(({ key, name, description }) => (
        <CheckboxWithInfo
          key={key}
          checked={filters[key]}
          onChange={(e) => handleChange(e, key)}
          uniqueId={`${prefix}${key}`}
          disabled={!counts?.[key] && !filters[key]}
          description={description}
        >
          {name} ({counts?.[key] || 0})
        </CheckboxWithInfo>
      ))}
    </div>
  );
};

FilterDistricts.defaultProps = defaultProps;

export default FilterDistricts;
