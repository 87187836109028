import { useContext } from 'react';
import { InitialStateContext } from '../context/InitialState';

export const usePredefined = () => {
  const { predefined } = useContext(InitialStateContext);

  const predefinedFiltersObject = predefined?.filters?.split(',').reduce((acc, filter) => {
    acc[filter.trim()] = true;
    return acc;
  }, {});

  return {
    ...predefined,
    ...(predefinedFiltersObject
      ? {
          filters: {
            ...predefinedFiltersObject,
          },
        }
      : {}),
  };
};
