import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import HotelHitOrigo from './hotelhit/HotelHitOrigo';
import getHotelUrl from '../utils/getHotelUrl';
import { usePax } from '../hooks/usePax';
import { useInitialState } from '../hooks/useInitialState';
import { useDepartures } from '../hooks/useDepartures';
import { useMucd } from '../hooks/useMucd';
import { logToDataLayer } from '../hooks/useAnalytics';
import { toSiteId } from '../../server/util/idToMucd';

const getPaxString = (pax, texts) => {
  if (pax) {
    const [nAdults, nChildren] = pax.split('_').map((n) => parseInt(n, 10));

    const str = `${nAdults} ${nAdults > 1 ? texts.adults : texts.adult}`;
    if (!nChildren) {
      return str;
    }

    return `${str}, ${nChildren} ${nChildren > 1 ? texts.children : texts.child}`;
  }

  return null;
};

const showPricePerPerson = (pax) => {
  if (pax) {
    const [nAdults, nChildren] = pax.split('_').map((n) => parseInt(n, 10));

    return nAdults === 2 && nChildren === 0;
  }

  return false;
};

const HotelContainer = (props) => {
  const { hotelObject, position, location, openPriceDetails, onClose } = props;

  const mucd = useMucd();

  const {
    meta,
    umbracoTexts,
    text,
    travelType,
    imageUrl: imageDomain,
    featureToggles,
  } = useInitialState();

  const { links: umbracoLinks } = umbracoTexts;

  const { selectedDeparturesOption: selectedDeparture } = useDepartures(location.searchParams);

  const { pax, roomAges: paxAges = [] } = usePax(location.searchParams);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (parseInt(params.get('details'), 10) === hotelObject.wvId) {
      openPriceDetails(hotelObject);
    }
  }, [hotelObject.wvId, openPriceDetails, hotelObject]);

  if (!hotelObject) {
    return null;
  }

  const charterHotelCode =
    hotelObject?.hotel?.__meta?.hotelCode ||
    hotelObject?.hotel?.content?.parts?.[0]?.codes?.[0]?.hotCd;

  const impression = {
    name: [
      hotelObject?.hotel?.__meta?.departureCode || selectedDeparture?.[0]?.key,
      hotelObject?.hotel?.__meta?.destinationCode || meta?.destinationCode,
    ]
      .filter((x) => x)
      .join(' - '),
    id: charterHotelCode
      ? [hotelObject?.hotel?.__meta?.destinationCode || meta?.destinationCode, charterHotelCode]
          .filter((x) => x)
          .join('')
      : hotelObject?.hotel?.content?.parts?.[0]?.codes?.[0]?.code,
    price: hotelObject?.hotel?.priceOffer?.totalPrice || '',
    brand: hotelObject?.hotel?.mainCategory?.name || 'Other',
    category: travelType,
    variant: 'VITS',
    list: 'Hotel Finder List',
    position,
  };
  let hotelUrl = hotelObject?.hotel?.content?.url;

  if (hotelObject?.hotel?.priceOffer) {
    hotelUrl = getHotelUrl(
      hotelObject,
      umbracoLinks,
      location.searchParams,
      mucd,
      selectedDeparture,
      paxAges.toString()
    );
  }

  const showPriceDetailsLink = () => {
    if (hotelObject?.isIndependent) return false;

    const isVingSe = toSiteId(mucd) === 1;
    return isVingSe ? featureToggles?.showPricedetails : true;
  };

  return (
    <HotelHitOrigo
      squareImage
      id={hotelObject.wvId}
      mucd={mucd}
      origoData={hotelObject.hotel}
      texts={text}
      paxLabel={getPaxString(pax, umbracoTexts?.labels)}
      hotelUrl={hotelUrl}
      useContentUrl
      useNewPriceCommunication
      openPriceDetails={showPriceDetailsLink() ? () => openPriceDetails(hotelObject) : false}
      showPricePerPerson={showPricePerPerson(pax)}
      onSelect={() =>
        logToDataLayer({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: {
                list: 'Hotel Finder List',
              },
              products: [impression],
            },
          },
        })
      }
      onClose={onClose}
      isMapView={location.searchParams?.view === 'map'}
      imageDomain={imageDomain}
    />
  );
};

export const fragment = gql`
  fragment HotelContainerHotel on Hotel {
    content {
      caId
      parts {
        codes {
          hotCd
          code
        }
      }
      geographical {
        country {
          caId
        }
        resort {
          caId
        }
      }
    }
  }
`;

export default HotelContainer;
