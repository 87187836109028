import React from 'react';
import { Link } from '@bwoty-web/styleguide';
import Message from '../messages/Message';
import { useInitialState } from '../../hooks/useInitialState';

export const DetailsErrorDumb = (props) => {
  const { retryDetails } = props;

  const { umbracoTexts } = useInitialState();
  const error = umbracoTexts?.messages?.detailsError;

  if (!error) {
    return null;
  }
  const messageSplit = (error?.message || '').split(/{|}/);
  const type = error?.type || '';

  if (messageSplit.length !== 3) {
    return null;
  }
  return (
    <div>
      <Message header={error?.header} isInitiallyExpanded type={type.toLowerCase()}>
        {messageSplit[0]}
        <Link onClick={() => retryDetails()}>{messageSplit[1]}</Link>
        {messageSplit[2]}
      </Message>
    </div>
  );
};

export default DetailsErrorDumb;
