import { CONVERT_DURATION_DICT } from './filterDictionaries';

const allCount = '10000';

/**
 * It is sugested to bring this logic to a server (at the moment, origo) and make a more
 * modern apollo accurate call including all the variabels found in the object put as an
 * argument for this function.
 */
export const generateHotelFinderApiKey = ({
  isInitialQuery = false,
  isMap,
  currentPage,
  departureDate,
  months,
  geoId,
  mergedFilters,
  orderBy,
  departureString,
  duration,
  todayString,
  marketUnitKey,
  pax,
  hotelType,
  offset,
  monthsString,
}) => {
  const periodKeyBase =
    'tf/{geoId}/{mucd}/{hotelType}/{departure}/{duration}/{period}/{pax}/{skip}/{count}/{orderBy}/{filters}';
  const dateKeyBase =
    'tf/{geoId}/{mucd}/{hotelType}/{departure}/{duration}/{departureDate}/{offset}/{pax}/{skip}/{count}/{orderBy}/{filters}';

  let fetchCount;
  let skip;

  if (!isInitialQuery) {
    const page = Math.max((parseInt(currentPage || '1', 10) || 1) - 1, 0);
    skip = page * 10;
    fetchCount = isMap ? allCount : '10';
  } else {
    const page = Math.max(parseInt(currentPage || '1', 10) || 1, 0);
    skip = 0;
    fetchCount = isMap ? allCount : page * 10;
  }

  if (!departureDate && !months) {
    return dateKeyBase
      .replace('{geoId}', geoId || '0')
      .replace('{skip}', '0')
      .replace('{count}', fetchCount)
      .replace('{filters}', mergedFilters)
      .replace('{orderBy}', orderBy || 'Y')
      .replace('{departure}', departureString)
      .replace('{duration}', CONVERT_DURATION_DICT[duration])
      .replace('{departureDate}', todayString)
      .replace('{offset}', 1)
      .replace('{mucd}', marketUnitKey)
      .replace('{pax}', pax)
      .replace('{hotelType}', hotelType);
  }

  if (departureDate) {
    return dateKeyBase
      .replace('{geoId}', geoId || '0')
      .replace('{skip}', skip)
      .replace('{count}', fetchCount)
      .replace('{filters}', mergedFilters)
      .replace('{orderBy}', orderBy || 'Y')
      .replace('{departure}', departureString)
      .replace('{duration}', CONVERT_DURATION_DICT[duration])
      .replace('{departureDate}', departureDate)
      .replace('{offset}', offset)
      .replace('{mucd}', marketUnitKey)
      .replace('{pax}', pax)
      .replace('{hotelType}', hotelType);
  }

  return periodKeyBase
    .replace('{geoId}', geoId || '0')
    .replace('{skip}', skip)
    .replace('{count}', fetchCount)
    .replace('{filters}', mergedFilters)
    .replace('{orderBy}', orderBy || 'Y')
    .replace('{departure}', departureString)
    .replace('{duration}', CONVERT_DURATION_DICT[duration])
    .replace('{mucd}', marketUnitKey)
    .replace('{period}', monthsString)
    .replace('{pax}', pax)
    .replace('{hotelType}', hotelType);
};
