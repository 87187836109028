import convertMucd from './convertMucd';

const separator = {
  1: '.',
  3: ' ',
  11: '.',
  15: ' ',
  18: '.',
};

const currencySign = {
  1: ':-',
  3: ',—',
  11: ',-',
  15: ',-',
  18: ':-',
};

const currencyToMucdDictionary = {
  SEK: 'vs',
  NOK: 'vn',
  DKK: 'sd',
  EUR: 'tf',
};

export function formatPrice(price, mucd) {
  const siteId = convertMucd(mucd);
  const trimmed = Math.round(price || 0);
  const priceString = trimmed
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator[siteId]}`);
  return `${priceString}${currencySign[siteId]}`;
}

export function formatPriceCurrency(price, currency) {
  return formatPrice(price, currencyToMucdDictionary[currency]);
}
