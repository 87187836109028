import { useMemo } from 'react';

export const getDepartureDate = (searchParams) => {
  if (!searchParams?.QueryDepDate) {
    return '';
  }
  return searchParams?.QueryDepDate;
};

export const getDepartureDateInterval = (searchParams) => {
  if (!searchParams?.QueryDepInterval) {
    return '0';
  }
  return searchParams?.QueryDepInterval;
};

export const useDepartureDate = (searchParams) => {
  const departureDate = useMemo(() => getDepartureDate(searchParams), [searchParams]);
  const departureDateInterval = useMemo(
    () => getDepartureDateInterval(searchParams),
    [searchParams]
  );

  return {
    departureDate,
    departureDateInterval,
  };
};
