import React, { useMemo, memo } from 'react';
import styled from 'styled-components';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { DatePicker as CoreDatePicker } from '@bwoty-web/styleguide';
import { MonthFooter } from './MonthFooter';
import { useSiteId } from '../../hooks/useSiteId';

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  @media (min-width: ${deviceWidths.tablet}) {
    padding: 0 20px 30px 20px;
  }
`;

const DatePickerContent = styled.div`
  height: 100%;
  position: relative;

  @media (max-width: ${deviceWidths.tabletMax}) {
    overflow-y: scroll;
  }
  @media (min-width: ${deviceWidths.tabletMax}) {
    overflow: hidden;
    display: flex;
  }
`;

function createFooterRenderer(selectedMonths, onMonthClick, label) {
  return function ({ month, year, monthName }) {
    return (
      <MonthFooter
        selectedMonths={selectedMonths}
        month={month}
        year={year}
        label={label}
        monthName={monthName}
        onSelect={onMonthClick}
      />
    );
  };
}

export const DatePicker = memo((props) => {
  const {
    startDate,
    selectedDate,
    selectedMonths,
    selectedInterval,
    onDayClick,
    onMonthClick,
    texts,
    isOpen,
  } = props;

  const siteId = useSiteId();

  const renderFooterMemo = useMemo(() => {
    return createFooterRenderer(selectedMonths, onMonthClick, texts.chooseAllDatesIn);
  }, [selectedMonths, onMonthClick, texts.chooseAllDatesIn]);

  return (
    <Wrapper>
      <DatePickerContent>
        <CoreDatePicker
          siteId={siteId}
          startDate={startDate}
          nrOfMonths={24}
          key={isOpen ? 'datepicker' : 'hidden-picker'}
          onChange={onDayClick}
          selectedDate={selectedDate}
          selectedMonths={selectedMonths}
          selectedDateInterval={selectedInterval || undefined}
          monthFooter={renderFooterMemo}
        />
      </DatePickerContent>
    </Wrapper>
  );
});
