import { useContext } from 'react';

import { InitialStateContext } from '../context/InitialState';
import { DURATION_DICTIONARY } from '../utils/filterDictionaries';

export const useUmbracoTexts = () => {
  const { umbracoTexts } = useContext(InitialStateContext);
  return umbracoTexts;
};

export const useUmbracoFilterTexts = () => {
  const umbracoFilters = useUmbracoTexts().filters || {};
  const umbracoFiltersText = {};

  Object.keys(umbracoFilters).forEach((groupKey) => {
    // Popular är en grupp som har filter från andra gruppen, därför går vi inte igenom
    // den alls då vi skulle gå igenom samma saker två gånger.
    if (groupKey !== 'popular') {
      Object.keys(umbracoFilters[groupKey].values).forEach((valueKey) => {
        // TODO: Varför groupKey name?

        const umbracoFilterObject = umbracoFilters[groupKey].values[valueKey];

        // TODO: Varför kollar vi string? Vill ha samma struktur på objektet
        if (typeof umbracoFilterObject === 'string') {
          umbracoFiltersText[valueKey] = {
            name: umbracoFilterObject || umbracoFilters[groupKey].name,
          };
        } else {
          // TODO: Varför finns det ibland en key och ibland inte? allAllInclusive, en grupp man filtrerat på
          umbracoFiltersText[umbracoFilterObject.key || valueKey] = {
            name: umbracoFilters[groupKey].name,
            ...umbracoFilterObject,
          };

          // Om det finns values så finns det barn till en filtergrupp, t.ex. Familjehotell har barnen
          // sunwing, family garden, etc, och dom behöver läggas till i översättningen också
          Object.keys(umbracoFilterObject.values || []).forEach((childKey) => {
            umbracoFiltersText[childKey] = {
              name: umbracoFilterObject.values[childKey],
            };
          });
        }
      });
    }
  });

  return umbracoFiltersText;
};

export const useUmbracoDurationOptions = () => {
  const umbracoTexts = useUmbracoTexts();

  const durations = umbracoTexts?.labels?.durations || {};

  return Object.keys(durations)
    .map((key, i) => {
      const name = durations[key];
      if (!name || typeof name !== 'string') {
        return null;
      }
      return {
        name,
        value: DURATION_DICTIONARY[key],
        sortOrder: i,
      };
    })
    .filter(x => x)
    .sort((a, b) => a.sortOrder - b.sortOrder);
};
