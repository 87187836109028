import React, { useEffect, useState, useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { createPortal } from 'react-dom';
import * as S from './style';
import MapView from './MapView';
import { useLocationContext } from '../../context/Location';

const MapContainer = ({ hasAll, hotels, hotelIds, fetchAllHotels, isLoadingHotels }) => {
  const location = useLocationContext();
  const tabletWidth = parseInt(deviceWidths.tablet.replace('px', ''), 10);

  const [renderToPortal, setRenderToPortal] = useState(false);

  const { searchParams, actions } = location;

  const isVisible = searchParams.view === 'map';

  const [selected, setSelected] = useState(-1);
  const [closing, setClosing] = useState(false);
  // to prevent ssr
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!hasAll && isVisible) {
      fetchAllHotels();
    }
  }, [hasAll, isVisible]);

  const onResize = useCallback(() => {
    // if screen is smaller than tablet, set mapRef to document.body
    if (window.innerWidth < tabletWidth) {
      setRenderToPortal(true);
    } else {
      setRenderToPortal(false);
    }
  }, [tabletWidth]);

  useEffect(() => {
    onResize();
    if (!window) return;
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    if (!mounted) {
      // client side now
      setMounted(true);
    }
  }, [mounted]);

  const hotelsWithLocation = useMemo(
    () =>
      hotelIds
        .map((id) => hotels[id])
        .filter((hotelObject) => hotelObject?.hotel?.content?.position),
    [hotelIds, hotels]
  );

  const handleClose = useCallback(() => {
    actions.setSearchParam('view', '');
  }, [actions]);

  if (!isVisible) {
    return null;
  }

  const map = (
    <S.MapContainer>
      {mounted ? (
        <MapView
          hotels={hotelsWithLocation}
          closing={closing}
          selectedIndex={selected}
          onSetSelected={setSelected}
          onSetClosing={setClosing}
          onClose={handleClose}
          isLoadingHotels={isLoadingHotels}
          location={location}
        />
      ) : null}
    </S.MapContainer>
  );

  if (renderToPortal) {
    return createPortal(map, document.body);
  }

  return map;
};

export const fragment = gql`
  fragment MapContainerHotel on Hotel {
    content {
      position {
        longitude
        latitude
      }
    }
  }
`;

MapContainer.defaultProps = {
  hotels: [],
};

export default MapContainer;
