import styled, { css } from 'styled-components';
import { fonts } from '@bwoty-web/styleguide/variables/fonts';
import { colorAliases, colors } from '@bwoty-web/styleguide/variables/colors';
import { InfoCard } from '@bwoty-web/styleguide';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { SwipeDownClose } from './SwipeDownClose';

export const MapContainer = styled.div`
  height: 700px;

  @media (max-width: ${deviceWidths.tabletMax}) {
    position: relative;
    z-index: 45;
  }
`;

export const StyledInfoCard = styled(InfoCard)`
  display: none;

  @media (min-width: ${deviceWidths.tablet}) {
    ${(props) =>
      !props.hidden &&
      css`
        display: block;
      `}
  }
`;

export const StyledInfoCardPrice = styled.div`
  font-family: ${fonts.bold};
  font-size: 18px;
  padding: 8px;
  color: ${(props) => (props.$isDiscount ? colors.rouge : colorAliases.text)};
`;

export const SwipeCard = styled(SwipeDownClose)`
  width: 100%;

  @media (min-width: ${deviceWidths.tablet}) {
    left: 12px;
    padding-bottom: 12px;
    width: auto;
  }
`;
