import React from 'react';
import { DistanceSlider } from '@bwoty-web/styleguide';
import SliderWrapper from './SliderWrapper';
import Heading from './Heading';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

const FilterCenter = (props) => {
  const { label, heading, searchParams, refetchHotels } = props;

  const hidden = searchParams.filter === 'true';

  const handleChangeComplete = (distanceCenterTo) => {
    const distance = parseInt(distanceCenterTo, 10) === 10000 ? '' : distanceCenterTo.toString();
    refetchHotels({
      newSearchParams: { distanceCenterTo: distance },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        params: { travelFilterCategory: 'Distance', travelFilterValue: 'Local centre' },
      },
    });
  };

  const { distanceCenterTo } = searchParams;

  return (
    <div>
      {heading && <Heading>{heading}</Heading>}
      <SliderWrapper>
        <DistanceSlider
          onChangeComplete={handleChangeComplete}
          uniqueId="distance_center"
          value={parseInt(distanceCenterTo, 10) || DistanceSlider.maxValue}
          valueLabel={label || 'Up to'}
          hidden={hidden}
        />
      </SliderWrapper>
    </div>
  );
};

FilterCenter.defaultProps = defaultProps;

export default FilterCenter;
