import React from 'react';
import * as S from './style';

const FilterCheckbox = ({ className, children, ...rest }) => (
  <S.StyledCheckbox className={className} {...rest}>
    {children}
  </S.StyledCheckbox>
);

export default FilterCheckbox;
