import styled, { css } from 'styled-components';
import { colors } from '@bwoty-web/styleguide/variables/colors';
import { fonts } from '@bwoty-web/styleguide/variables/fonts';
import { Icon, Checkbox } from '@bwoty-web/styleguide';

export const StyledCheckbox = styled(Checkbox)`
  font-size: 14px;
  margin-top: 4px;
  padding: 4px 12px;

  span {
    transition: none;
  }

  label {
    width: 100%;
  }
`;

export const StyledCheckboxInfoArrow = styled(Icon)`
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -5px;
  fill: currentColor;
`;

export const StyledCheckboxInfoLinkLabel = styled.span`
  border-bottom: 1px solid transparent;
`;

export const StyledCheckboxInfoLink = styled.button`
  font-size: 14px;
  color: ${colors.ocean};
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  ${(props) =>
    props.$open &&
    css`
      ${StyledCheckboxInfoArrow} {
        transform: rotate(180deg);
      }
    `}

  &:hover {
    ${StyledCheckboxInfoLinkLabel} {
      border-bottom-color: ${colors.ocean};
    }
  }
`;

export const StyledCheckboxInfoDescr = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-top: 12px;
  padding: 0 24px 8px;
`;

export const CheckboxGroup = styled.div`
  margin-left: 22px;
  margin-top: 4px;
  border-left: ${colors.lightGray} solid 1px;
  padding-left: 4px;
`;

export const FilterCollection = styled.div``;

export const FilterCollectionHeader = styled.button`
  background-color: ${colors.lightGrayL1};
  color: ${colors.black};
  display: flex;
  justify-content: space-between;
  font-family: ${fonts.bold};
  font-size: 16px;
  padding: 10px 8px;
  margin-top: 8px;
  width: 100%;

  ${(props) =>
    props.$expanded &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const FilterCollectionContent = styled.div`
  visibility: hidden;
  height: 0;

  ${(props) =>
    props.$expanded &&
    css`
      display: block;
      visibility: visible;
      height: auto;
    `}
`;

export const FilterTagsContainer = styled.div`
  padding: 5px 5px 5px 12px;

  button {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;
