// Uses selected* props to figure out which month display first in the popup.
// returns [yyyy, m] or null on invalid arguments
export function getFirstSelectedMonth(selectedMonths, selectedDate, selectedInterval) {
  // selectedMonths overrides the other two arguments so check it first.
  if (Array.isArray(selectedMonths) && selectedMonths.length) {
    // don't modify arguments
    const months = selectedMonths.slice();
    months.sort();
    try {
      return [parseInt(months[0].substr(0, 4), 10), parseInt(months[0].substr(4, 2), 10)];
    } catch (e) {
      // invalid format on date, ignore
      return null;
    }
  }

  if (selectedDate) {
    try {
      const n = parseInt(selectedInterval, 10) || 0;
      const d = new Date(selectedDate);
      // use selectedInterval to adjust startDate
      d.setDate(d.getDate() - n);
      return [d.getFullYear(), d.getMonth() + 1];
    } catch (e) {
      // invalid format on date, ignore
      return null;
    }
  }

  return null;
}

const pad = (n) => {
  if (n < 10) {
    return `0${n}`;
  }

  return n;
};

export function formatDate(date, mucd) {
  switch (mucd) {
    case 'vn':
      return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()}`;
    case 'sd':
      return `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${date.getFullYear()}`;
    case 'tf':
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    case 'vs':
    default:
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  }
}
