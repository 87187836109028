import React from 'react';
import styled from 'styled-components';
import { PopUp } from '@bwoty-web/styleguide';
import PriceDetailsInfo from './PriceDetailsInfo';
import PriceDetailsComponents from './PriceDetailsComponents';
import DetailsError from './DetailsError';
import { usePriceDetails } from '../../hooks/usePriceDetails';

const PriceDetailsPopupContent = styled.div``;

const PriceDetailsPopupDumb = ({ hotelObject, clearHotel }) => {
  const { priceDetails, priceDetailsTexts, isLoading, actions } = usePriceDetails(
    hotelObject,
    clearHotel,
  );

  if (!hotelObject?.hotel) {
    return null;
  }

  return (
    <PopUp
      size="large"
      header={priceDetailsTexts ? priceDetailsTexts.heading : ''}
      close={() => actions.clearPriceDetails()}
      defaultPadding
    >
      <PriceDetailsPopupContent>
        <PriceDetailsInfo
          hotel={hotelObject.hotel}
          details={priceDetails}
          isLoading={isLoading}
          priceDetailsTexts={priceDetailsTexts}
        />
        <PriceDetailsComponents
          details={priceDetails}
          isLoading={isLoading}
          priceDetailsTexts={priceDetailsTexts}
        />
        {priceDetails?.error && !isLoading && (
          <DetailsError retryDetails={actions.getPriceDetails} />
        )}
      </PriceDetailsPopupContent>
    </PopUp>
  );
};

export default PriceDetailsPopupDumb;
