import React from 'react';
import DurationOption from './DurationOption';
import Overlay from './SearchOverlay';
import { SelectedValue } from './SelectedValue';
import * as S from './style';
import { useDuration } from '../../hooks/useDuration';
import { useUmbracoDurationOptions, useUmbracoTexts } from '../../hooks/useUmbracoTexts';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const DurationContainer = ({ isOpen, onClose, refetchHotels, searchParams }) => {
  const durationOptions = useUmbracoDurationOptions();
  const { labels } = useUmbracoTexts();

  const { duration } = useDuration(searchParams);

  const onUpdate = (QueryDur) => {
    refetchHotels({
      newSearchParams: { QueryDur },
      analyticsData: { type: ANALYTICS_DATA_TYPES.SEARCH },
    });
    onClose();
  };

  const selectedDuration =
    durationOptions.find((dd) => dd.value === duration) || durationOptions[0];

  return (
    <S.DurationSelector>
      <SelectedValue label={labels.durationSelect} isOpen={isOpen}>
        {selectedDuration.name}
      </SelectedValue>
      <S.DurationPopup close={onClose} closed={!isOpen} header={labels.durationPopupHeader}>
        <S.DurationPopupBody>
          <S.DurationPopupTitle>{labels.durationOptionLabel}</S.DurationPopupTitle>
          {durationOptions.map((option) => (
            <DurationOption
              key={option.value}
              option={option}
              selected={selectedDuration.value === option.value}
              onUpdate={onUpdate}
            />
          ))}
        </S.DurationPopupBody>
      </S.DurationPopup>
      {isOpen && <Overlay onClick={onClose} />}
    </S.DurationSelector>
  );
};

export default DurationContainer;
