import styled, { css } from 'styled-components';
import { PopUpMobile, Icon } from '@bwoty-web/styleguide';
import { transitions } from '@bwoty-web/styleguide/variables/transitions';
import { colors } from '@bwoty-web/styleguide/variables/colors';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { fonts } from '@bwoty-web/styleguide/variables/fonts';

export const SelectedWrapper = styled.div`
  background-color: ${colors.white};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: ${fonts.default};
  height: 56px;
  line-height: 1;
  min-width: 100px;
  position: relative;
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const SelectedContent = styled.div`
  padding: 12px 12px 4px;
  height: 100%;
  transition: border-color ${transitions.default};

  &:focus {
    outline: none;
    border-color: ${colors.black};
  }

  .no-touch &:hover {
    background: ${colors.lightGray};
  }
`;

export const SelectedLabel = styled.span`
  color: ${colors.mediumGray};
  display: inline-block;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
`;

export const SelectedValue = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${fonts.bold};
  overflow: hidden;
  height: 21px;
  line-height: 24px;
`;

export const SelectedValueText = styled.span`
  overflow: hidden;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-family: ${fonts.bold};
`;

export const SearchPanelRow = styled.div`
  display: none;
  margin-bottom: 8px;
  width: ${(props) => `${props.w || 25}%`};
`;

export const SearchPanelItem = styled.div`
  flex: 1 1 100%;
  max-width: 100%;

  ${(props) =>
    props.inActive &&
    css`
      ${SelectedWrapper} {
        background-color: ${colors.lightGray};
      }
    `}
`;

export const SearchPanelDescrItem = styled.div`
  color: ${colors.almostblack};
  line-height: 16px;
  font-size: 16px;
  max-height: 48px;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

export const SearchPanelButtonItem = styled(SearchPanelItem)`
  display: none;

  button {
    width: 100%;
  }
`;

export const SearchPanelEditWrapper = styled.div`
  button {
    color: ${colors.almostblack};
    border-color: ${colors.almostblack};
  }
`;

export const SearchContainerRoot = styled.div`
  position: relative;
  background-color: ${colors.sunlight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  box-shadow: 0 0 3px 0 ${colors.spreadShadow};

  ${(props) =>
    props.isOpen &&
    css`
      flex-direction: column;
      align-items: initial;

      ${SearchPanelRow} {
        display: flex;

        @media (max-width: ${deviceWidths.tabletMax}) {
          width: 100%;
        }
      }

      ${SearchPanelButtonItem} {
        display: block;
      }

      ${SearchPanelEditWrapper} {
        display: none;
      }

      ${SearchPanelDescrItem} {
        display: none;
      }
    `}

  ${(props) =>
    props.isHidden &&
    css`
      display: none;
    `};

  @media (min-width: ${deviceWidths.tablet}) {
    flex-direction: row;

    ${SearchPanelEditWrapper} {
      display: none;
    }

    ${SearchPanelDescrItem} {
      display: none;
    }

    ${SearchPanelRow} {
      display: flex;
      margin-bottom: 0;
    }

    ${SearchPanelButtonItem} {
      display: none;
    }
  }
`;

export const SearchOverlay = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
`;

// Duration selection styles
export const DurationSelector = styled.div`
  @media (min-width: ${deviceWidths.tablet}) {
    position: relative;
    border-right: solid 1px ${colors.lightGray};
  }
`;

export const DurationPopupBody = styled.div``;

export const DurationPopupTitle = styled.div`
  text-transform: uppercase;
  color: ${colors.darkGrayL1};
  font-size: 12px;
  margin: 20px;
`;

export const DurationPopupOption = styled.button`
  display: flex;
  padding: 6px 8px 6px 12px;
  border-bottom: 1px solid ${colors.lightGray};
  width: 100%;
  height: 52px;
  font-size: 18px;
  font-family: 'Source Sans Pro', 'Arial';

  @media (min-width: ${deviceWidths.tablet}) {
    font-size: 16px;
  }

  ${(props) =>
    props.styleSelected &&
    css`
      color: ${colors.white};
      background-color: ${colors.darkCore};
    `}

  ${(props) =>
    !props.styleSelected &&
    css`
      &:hover {
        background-color: ${colors.lightGray};
      }
    `}
`;

export const DurationPopup = styled(PopUpMobile)`
  && {
    max-height: unset;
    @media (min-width: ${deviceWidths.tablet}) {
      position: absolute;
      top: 55px;
      left: 1px;

      ${DurationPopupBody} {
        padding: 0 32px 40px;
        width: 360px;
      }

      ${DurationPopupTitle} {
        margin: 10px 0;
      }

      ${DurationPopupOption} {
        padding: 6px 8px;
        height: auto;
        font-weight: normal;
      }
    }
  }
`;

// PAX selection styles
export const PaxSelector = styled.div`
  border-right: solid 1px ${colors.lightGray};

  @media (min-width: ${deviceWidths.tablet}) {
    position: relative;
  }
`;

export const PaxSelectorPopup = styled(PopUpMobile)`
  && {
    @media (min-width: ${deviceWidths.tablet}) {
      z-index: 10001; // Above the FilterBar
      max-height: unset;
      position: absolute;
      top: 55px;
      left: -210px;
      right: 0;
    }
  }
`;

export const PaxSelectorBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${deviceWidths.tablet}) {
    margin: 0 20px;
  }
`;

export const PaxSelectorOptionsWrap = styled.div`
  flex: 1 1 auto;
`;

export const PaxSelectorButtonWrap = styled.div`
  flex: 0 0 auto;
  min-height: 100px;
  padding: 0 10px;

  button {
    width: 100%;
  }

  @media (min-width: ${deviceWidths.tablet}) {
    min-height: auto;
    text-align: right;
    padding: 0;
    margin: 20px 0;

    button {
      width: auto;
    }
  }
`;

export const PaxPopupRow = styled.div`
  display: flex;
  min-width: 320px;
  margin: 0;
  padding: 10px;

  &:not(:first-child) {
    border-top: 1px solid ${colors.lightGray};
  }

  @media (min-width: ${deviceWidths.tablet}) {
    padding: 10px 0;
  }
`;

export const PaxPopupRowLeft = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PaxPopupRowAmount = styled.div`
  margin: 0 10px;
  font-size: 38px;
  font-family: ${fonts.light};
  color: ${colors.wave};
  width: 20px;
`;

export const PaxPopupRowInfo = styled.div``;

export const PaxPopupRowInfoTitle = styled.div`
  font-size: 16px;
  font-family: ${fonts.bold};
`;

export const PaxPopupRowInfoSubTitle = styled.div`
  font-size: 12px;
  color: ${colors.darkGrayL1};
`;

export const PaxPopupRowRight = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
`;

export const PaxPopupButton = styled.button`
  padding: 10px;
  color: ${colors.darkCore};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${colors.lightGray};
    `}
`;

export const PaxPopupButtonIcon = styled(Icon)`
  height: 30px;
  width: 30px;
  fill: currentColor;
`;

export const PaxPopupChildRow = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.lightGrayL1};
  justify-content: space-between;
  margin: 0;
  padding: 12px 20px;
  border-bottom: solid ${colors.lightGray};
  border-width: 1px 0;

  &:first-child {
    border-top: solid ${colors.lightGray};
    border-width: 1px 0;
  }
`;

// used by departure and date options
export const SearchOptionDropDownWrapper = styled.div`
  @media (min-width: ${deviceWidths.tablet}) {
    position: absolute;
    background-color: ${colors.sunlight};
    top: 75px;
    left: 0;
    right: 0;
    z-index: 10001; // above the FilterBar
    padding: 0 10px 15px;
    display: ${(props) => (props.styleOpen ? 'block' : 'none')};
  }
`;

export const SearchOptionDropDown = styled(PopUpMobile)`
  @media (max-width: ${deviceWidths.tabletMax}) {
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
  }

  @media (min-width: ${deviceWidths.tablet}) {
    margin-top: 4px;
  }
`;

export const SearchOptionDropDownInner = styled.div`
  position: absolute;
  top: 72px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${deviceWidths.tablet}) {
    position: static;
  }
`;

export const SearchOptionDropDownBody = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

export const SearchOptionDropDownFooter = styled.div`
  padding: 20px 24px;
  flex: 0 0 auto;
  background: ${colors.lightGrayL1};

  .tcne-hh-search-filter-update {
    width: 100%;
  }

  @media (min-width: ${deviceWidths.tablet}) {
    position: relative;
    text-align: right;
    display: flex;
    justify-content: space-between;

    .tcne-hh-search-filter-update {
      width: auto;
    }
  }
`;

export const SearchOptionDropDownButtonWrap = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

// Departure selection styles
export const DepartureSelector = styled.div`
  @media (min-width: ${deviceWidths.tablet}) {
    border-right: solid 1px ${colors.lightGray};
  }
`;

export const DepartureList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${deviceWidths.tablet}) {
    flex-direction: row;
    padding: 0 32px 32px;
  }
`;

export const DepartureColumn = styled.div`
  border-top: 1px solid ${colors.lightGray};

  @media (min-width: ${deviceWidths.tablet}) {
    width: 33%;
    padding-right: 15px;
    border-top-color: transparent;

    &:last-child {
      padding-right: 0;
    }
  }
`;

export const DepartureRow = styled.div`
  padding: 10px 12px;
  border-bottom: 1px solid ${colors.lightGray};

  &:last-child {
    border-bottom-color: transparent;
  }

  ${(props) =>
    props.styleSelected &&
    css`
      font-family: ${fonts.bold};
    `}

  @media (min-width: ${deviceWidths.tablet}) {
    &:first-child {
      border-top: 1px solid ${colors.lightGray};
    }
  }
`;

export const DepartureButtonWrap = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  button {
    width: 100%;
  }

  @media (min-width: ${deviceWidths.tablet}) {
    position: relative;
    text-align: right;

    button {
      width: auto;
    }
  }
`;
