import styled, { css } from 'styled-components';
import { colors } from '@bwoty-web/styleguide/variables/colors';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { transitions } from '@bwoty-web/styleguide/variables/transitions';
import { fonts } from '@bwoty-web/styleguide/variables/fonts';
import Link from './Link';

export const StyledFilterLink = styled(Link)`
  border: 2px solid ${colors.wave};
  border-radius: 100px;
  color: ${colors.wave};
  font-family: ${fonts.bold};
  font-size: 18px;
  background: ${colors.white};
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 100%;

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 2px;
    fill: ${colors.wave};
  }
`;

export const SortOptionsContainer = styled.div`
  overflow-x: auto;
  background: white;
  min-width: 100%;
  box-shadow: 0 0 8px ${colors.mediumGrayL1};
  margin-top: 10px;
  white-space: nowrap;
  right: 0;
  margin-right: -24px;

  @media (min-width: ${deviceWidths.tablet}) {
    display: flex;
    position: inherit;
    justify-content: space-between;
    margin-top: 12px;
    padding: 0 8px;
    margin: inherit;
    background: inherit;
    box-shadow: none;
    width: inherit;

    ${(props) =>
      props.isHidden &&
      css`
        visibility: hidden;
      `}
  }
`;

export const SortOptionMobileContainer = styled.div`
  width: 100%;
  position: relative;
  left: -12px;

  @media (min-width: ${deviceWidths.tablet}) {
    width: inherit;
    position: inherit;
    left: 0;
  }
`;

export const SortOption = styled.button`
  color: ${colors.darkGrayL1};
  font-family: ${fonts.light};
  text-transform: uppercase;
  transition: border-color ${transitions.default};
  font-size: 16px;
  border-bottom: 2px solid transparent;
  padding: 12px;

  ${(props) =>
    props.isSelected &&
    css`
      border-color: ${colors.darkCore};
      color: ${colors.darkGray};
      font-family: ${fonts.bold};
      pointer-events: none;
    `}

  @media (min-width: ${deviceWidths.tablet}) {
    margin: 6px 12px 12px;
    padding: 0px;
  }
`;

export const ViewOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 0;
  justify-content: center;
  align-items: center;

  ${StyledFilterLink} {
    display: none;
  }

  @media (min-width: ${deviceWidths.tablet}) {
    margin-top: 0px;
  }
`;

export const HotelCountLabel = styled.div`
  font-size: 17px;
`;

export const ViewOptions = styled.div`
  flex: 1 1 0%;
  text-align: right;
`;

export const ViewOption = styled(Link)`
  color: ${colors.darkGrayL1};
  display: inline-flex;
  font-family: ${fonts.bold};
  font-size: 18px;
  line-height: 24px;
  transition: color ${transitions.default};
  margin-left: 20px;

  & svg {
    width: 20px;
    height: 20px;
    padding-right: 4px;
    fill: currentColor;
  }
`;

export const RootContainer = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 24px 12px 12px;

  @media (min-width: ${deviceWidths.tablet}) {
    flex-direction: row-reverse;

    ${StyledFilterLink} {
      display: none;
    }

    ${SortOptionsContainer}, ${ViewOptionsContainer} {
      width: auto;
      white-space: nowrap;
    }

    ${ViewOptionsContainer} {
      flex: 1 1 0%;

      ${StyledFilterLink} {
        border: none;
        display: inline-flex;
        background: none;
        width: auto;
        height: auto;
        font-family: ${fonts.bold};
        font-size: 18px;
        line-height: 24px;
        margin-left: 20px;
        text-transform: none;
        color: ${colors.darkGrayL1};

        & svg {
          fill: ${colors.darkGrayL1};
        }
      }
    }
  }

  @media (min-width: ${deviceWidths.desktop}) {
    padding: 24px 0 12px;

    ${ViewOptionsContainer} ${StyledFilterLink} {
      display: none;
    }
  }
`;
