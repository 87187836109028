import React from 'react';
import DepartureRow from './DepartureRow';
import * as S from './style';

const splitIntoColumns = (departures = []) => {
  if (!departures.length) {
    return [];
  }

  const firstSplit = Math.ceil(departures.length / 3);
  const col1 = departures.slice(0, firstSplit);
  const secondSplit = Math.ceil((departures.length - firstSplit) / 2);
  const col2 = departures.slice(firstSplit, firstSplit + secondSplit);
  const col3 = departures.slice(firstSplit + secondSplit, departures.length);
  return [col1, col2, col3];
};

const DepartureBody = ({ departures, onSelect, selectedDepartures, isOpen }) => {
  if (!departures || !departures.length || !isOpen) {
    return null;
  }

  const columns = splitIntoColumns(departures);

  return (
    <S.DepartureList>
      {columns.map((column, i) => {
        return (
          <S.DepartureColumn key={i}>
            {column.map((departure) => (
              <DepartureRow
                key={departure.key}
                departure={departure}
                onSelect={(e) => onSelect(e, departure.key)}
                selectedDepartures={selectedDepartures}
              />
            ))}
          </S.DepartureColumn>
        );
      })}
    </S.DepartureList>
  );
};

export default DepartureBody;
