/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Icon } from '@bwoty-web/styleguide';
import {
  USER_INTERFACE_EQUALIZER_ROTATED,
  TIME_LOCATION_MAP_ROUTE,
  USER_INTERFACE_LIST,
} from './iconPaths';
import * as S from './sortBarContainerStyles';
import { useInitialState } from '../hooks/useInitialState';
import { ANALYTICS_DATA_TYPES } from '../hooks/useAnalytics';

import { useLocationContext } from '../context/Location';
import { useHotelsContext } from '../context/Hotels';
import { useMucd } from '../hooks/useMucd';

const FilterLink = ({ label, location, refetchHotels }) => {
  return (
    <S.StyledFilterLink
      queryUpdate={{ filter: 'true' }}
      gtmAttribute="Filter button"
      location={location}
      refetchHotels={refetchHotels}
    >
      <Icon path={USER_INTERFACE_EQUALIZER_ROTATED} />
      {label}
    </S.StyledFilterLink>
  );
};

const changeFilter = (e) => {
  const element = e.target;
  let parentScrollLeft = element.parentNode.scrollLeft;
  const max = element.offsetLeft;

  // Smaller steps back else it wont be smooth when scrolling to e.g. popular filter
  let negativeScroll = 0;

  const handler = setInterval(() => {
    if (parentScrollLeft > max) {
      element.parentNode.scrollLeft -= negativeScroll;
      negativeScroll += 2;
      parentScrollLeft -= 2;
      if (parentScrollLeft <= max) clearInterval(handler);
    } else {
      element.parentNode.scrollLeft += parentScrollLeft;
      parentScrollLeft += 10;
      if (parentScrollLeft >= max) clearInterval(handler);
    }
  }, 20);
};

const SortBarContainer = () => {
  const location = useLocationContext();
  const { totalCount, filteredHotelCount, refetchHotels } = useHotelsContext();

  const { searchParams, hasFilters } = location;

  const { umbracoTexts } = useInitialState();
  const texts = umbracoTexts?.labels || {};
  const hideMap = umbracoTexts?.hideMap?.boolean || false;

  const queryOrderBy = useMemo(() => searchParams.orderBy, [searchParams.orderBy]);

  const filterRef = useRef(null);
  const [hasMadeInitialScroll, setHasMadeInitialScroll] = useState(null);
  const [filterWidth, setFilterWidth] = useState(null);

  useEffect(() => {
    if (filterRef.current) {
      switch (queryOrderBy) {
        case 'Y':
          setFilterWidth(filterRef.current.firstChild.offsetWidth * 0);
          break;
        case 'DI':
          setFilterWidth(filterRef.current.firstChild.offsetWidth * 1);
          break;
        case 'LP':
          setFilterWidth(filterRef.current.firstChild.offsetWidth * 2);
          break;
        case 'HGR':
          setFilterWidth(filterRef.current.firstChild.offsetWidth * 3);
          break;
        default:
          setFilterWidth(filterRef.current.firstChild.offsetWidth * 0);
          break;
      }
    }
  }, [queryOrderBy]);

  useEffect(() => {
    // scroll to selected
    if (!hasMadeInitialScroll && filterWidth) {
      // -30 to show a part of the hidden filter
      filterRef.current.scrollLeft = filterWidth - 30;
      setHasMadeInitialScroll(true);
    }
  }, [filterRef, hasMadeInitialScroll, filterWidth, setHasMadeInitialScroll]);

  const onSortClick = (e) => {
    changeFilter(e);
    refetchHotels({
      newSearchParams: { orderBy: e.target.value },
      analyticsData: { type: ANALYTICS_DATA_TYPES.SORT, params: { value: e.target.value } },
    });
  };

  const selectedView = searchParams.view === 'map' ? 'map' : 'list';
  const isVingSe = useMucd() === 'vs';

  return (
    <S.RootContainer>
      <FilterLink label={texts.filter} location={location} refetchHotels={refetchHotels} />
      <S.SortOptionMobileContainer>
        <S.SortOptionsContainer ref={filterRef} isHidden={selectedView === 'map'}>
          <S.SortOption
            isSelected={queryOrderBy !== 'LP' && queryOrderBy !== 'HGR' && queryOrderBy !== 'DI'}
            type="button"
            onClick={onSortClick}
            value="Y"
            data-test="hf-sort-y"
          >
            {texts.sortOptions?.Y}
          </S.SortOption>
          {
            !isVingSe && (
              <S.SortOption
              isSelected={queryOrderBy === 'DI'}
              type="button"
              onClick={onSortClick}
              value="DI"
              data-test="hf-sort-di"
            >
              {texts.sortOptions?.DI}
            </S.SortOption>
            )
          }
          <S.SortOption
            isSelected={queryOrderBy === 'LP'}
            type="button"
            onClick={onSortClick}
            value="LP"
            data-test="hf-sort-lp"
          >
            {texts.sortOptions?.LP}
          </S.SortOption>
          <S.SortOption
            isSelected={queryOrderBy === 'HGR'}
            type="button"
            onClick={onSortClick}
            value="HGR"
            data-test="hf-sort-hgr"
          >
            {texts.sortOptions?.HGR}
          </S.SortOption>
        </S.SortOptionsContainer>
      </S.SortOptionMobileContainer>
      <S.ViewOptionsContainer>
        <S.HotelCountLabel>
          {hasFilters && `${filteredHotelCount} ${texts?.of} `}
          {`${totalCount} ${texts.hotels}`}
        </S.HotelCountLabel>
        <S.ViewOptions>
          {selectedView === 'list' && !hideMap && (
            <S.ViewOption
              onClick={() => location.actions.setSearchParam('view', 'map')}
              location={location}
              className="gtm-map-view"
            >
              <Icon path={TIME_LOCATION_MAP_ROUTE} />
              <span>{texts.map}</span>
            </S.ViewOption>
          )}
          {selectedView !== 'list' && (
            <S.ViewOption
              onClick={() => location.actions.setSearchParam('view', '')}
              location={location}
              className="gtm-list-view"
            >
              <Icon path={USER_INTERFACE_LIST} />
              <span>{texts.list}</span>
            </S.ViewOption>
          )}
          <FilterLink label={texts.filter} location={location} />
        </S.ViewOptions>
      </S.ViewOptionsContainer>
    </S.RootContainer>
  );
};

export default SortBarContainer;
