import React from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../ErrorBoundary';

const Root = styled.div`
  width: 100%;
  padding: 4px 12px;
`;

const SliderWrapper = (props) => {
  const { children } = props;
  if (!children) {
    return null;
  }
  return (
    <Root>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Root>
  );
};

export default SliderWrapper;
