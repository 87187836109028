import React from 'react';
import formatPrice from '@bwoty-web/price-formatter';
import { Tags } from '@bwoty-web/styleguide';
import * as S from './style';
import { useMucd } from '../../hooks/useMucd';
import { useUmbracoFilterTexts } from '../../hooks/useUmbracoTexts';
import { usePreparedFacets } from '../../hooks/usePreparedData';
import { getActiveFiltersWithTextAndKey } from '../../utils/getActiveFiltersWithTextAndKey';
import { useInitialState } from '../../hooks/useInitialState';

const defaultProps = {
  prefix: '',
};

const timeSign = {
  vs: 'tim',
  vn: 'tim',
  sd: 't',
  tf: 't',
  ds: 'tim',
};

export const getValueString = (translatedObject, mucd) => {
  const { key, values, value } = translatedObject;

  if (values && values.length) {
    const firstSplit = values[0].split('');
    const secondSplit = values[1].split('');
    return `: ${firstSplit[0]},${firstSplit[1]} - ${secondSplit[0]},${secondSplit[1]}`;
  }

  if (key === 'priceTo') {
    return `: ${formatPrice(value, mucd)}`;
  }

  if (key === 'distanceBeachTo' || key === 'distanceCenterTo') {
    return `: ${value} m`;
  }

  if (key === 'maxTransfer') {
    return `: ${value} ${timeSign[mucd]}`;
  }

  if (value) return `: ${value}`;

  return '';
};

const mapFilterTag = (translatedObject, mucd) => {
  const { key, keys, name, shortName } = translatedObject;

  return {
    text: `${shortName || name}${getValueString(translatedObject, mucd)}`,
    key,
    keys,
    theme: 'filter',
  };
};

const FilterTags = (props) => {
  const { searchParams, refetchHotels } = props;

  const mucd = useMucd();

  const filterTexts = useUmbracoFilterTexts();
  const { geographicalDictionary } = useInitialState();

  const preparedFacets = usePreparedFacets();

  const allFilters = getActiveFiltersWithTextAndKey(
    searchParams,
    preparedFacets,
    filterTexts,
    geographicalDictionary
  );

  const onTagClick = (tag) => {
    if (
      tag.key === 'priceTo' ||
      tag.key === 'distanceBeachTo' ||
      tag.key === 'distanceCenterTo' ||
      tag.key === 'maxTransfer'
    ) {
      refetchHotels({ newSearchParams: { [tag.key]: '' } });
    } else if (tag.keys) {
      refetchHotels({ newSearchParams: { [tag.keys[0]]: '', [tag.keys[1]]: '' } });
    } else {
      refetchHotels({ newSearchParams: { filters: { [tag.key]: '' } } });
    }
  };

  return (
    <S.FilterTagsContainer>
      <Tags small onTagClick={onTagClick} tags={allFilters.map((tag) => mapFilterTag(tag, mucd))} />
    </S.FilterTagsContainer>
  );
};

FilterTags.defaultProps = defaultProps;

export default FilterTags;
