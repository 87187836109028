import gql from 'graphql-tag';

export const hotelFragment = gql`
  fragment HotelHitOrigoHotel on Hotel {
    content {
      name
      url
      guestRating
      doubledRating
      hotelListQuickFacts {
        id
        title
        displayValue
      }
      listImage {
        relativeUrl
        srcSet
        src
        sources {
          srcSet
          type
          media
        }
      }
      geographical {
        districts {
          name
          id
        }
        resort {
          name
          uri: url
        }
        area {
          name
          uri: url
        }
      }
      tripAdvisor {
        averageRating
        ratingImage
      }
      productInfo {
        mealAncillary
        isFamilyHotel
        isAdultHotel
        isEcoCertified
      }
      isFlightOnly
      isUnspecified
    }
    mainCategory {
      name
      usp {
        header
        items(count: 5)
      }
      images(types: ["x-large"]) {
        url
      }
    }
    lolloAndBernie {
      name
      images(types: ["bannerLarge"]) {
        url
      }
    }
    addedBenefit {
      name
    }
    accommodations {
      rooms {
        boardCodeDescription
        boardCode
        numberOfOrdinaryBeds
      }
    }
    priceOffer {
      currencyCode
      totalPrice
      totalPriceText
      ordinaryPrice
      adultPrice
      adultPriceText
      ordinaryPriceText
      discount
      discountText
      roomTypeName
      departureAirport
      departureDate
    }
    priority
  }
`;

export const textsFragment = gql`
  fragment HotelHitOrigoTexts on Text {
    webuiHotelHit: ca (environment: DMZ, names: WEBUIHOTELHIT)
  }
`;
