import { useMemo } from 'react';

export const getPriceFilter = (searchParams) => {
  if (!searchParams.priceTo) {
    return [];
  }
  const to = parseInt(searchParams.priceTo, 10);
  if (!to) {
    return [];
  }
  return [`price${to}`];
};

export const usePriceFilter = (searchParams) => {
  const priceFilter = useMemo(() => getPriceFilter(searchParams), [searchParams]);
  return { priceFilter };
};
