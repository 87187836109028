import React, { useEffect, useState } from 'react';
import { Button } from '@bwoty-web/styleguide';
import PaxContainer from './PaxContainer';
import DepartureContainer from './DepartureContainer';
import DurationContainer from './DurationContainer';
import DepartureDateContainer from './DepartureDateContainer';
import * as S from './style';
import { usePax } from '../../hooks/usePax';
import { useDepartures } from '../../hooks/useDepartures';
import { useDuration } from '../../hooks/useDuration';
import { CONVERT_DURATION_DICT } from '../../utils/filterDictionaries';
import { useInitialState } from '../../hooks/useInitialState';
import { useLocationContext } from '../../context/Location';
import { useHotelsContext } from '../../context/Hotels';

const setVhVariable = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const addBodyNoScroll = () => {
  document.body.classList.add('tcne-hf-datepicker-week__no-scroll');
};

const removeBodyNoScroll = () => {
  document.body.classList.remove('tcne-hf-datepicker-week__no-scroll');
};

const SearchContainer = () => {
  const [isOpen, setOpen] = useState(false);
  const [activeTab, setActivedTab] = useState(-1);

  const location = useLocationContext();
  const { searchParams } = location;
  const { refetchHotels } = useHotelsContext();

  const { pax } = usePax(searchParams);
  const { selectedDepartures, allDepartures } = useDepartures(searchParams);
  const { duration } = useDuration(searchParams);
  const { labels, showSearch } = useInitialState().umbracoTexts;
  const { boolean: showSearchContainer } = showSearch;

  const shortDescription = [
    selectedDepartures
      .map((x) => allDepartures.find((o) => o.key === x)?.value)
      .filter((x) => x)
      .join(', '),
    labels?.paxOptions?.[pax],
    labels?.durations?.[CONVERT_DURATION_DICT[duration]],
  ]
    .filter((x) => x)
    .join(', ');

  useEffect(() => {
    setVhVariable();
    window.addEventListener('resize', setVhVariable, false);
    return () => {
      removeBodyNoScroll();
      window.removeEventListener('resize', setVhVariable, false);
    };
  }, []);

  const onSetActiveTab = (name) => () => {
    if (name === 'date') {
      addBodyNoScroll();
    } else {
      removeBodyNoScroll();
    }
    setActivedTab(name);
  };

  const closeTab = (e) => {
    if (e) e.stopPropagation();
    removeBodyNoScroll();
    setActivedTab(-1);
  };

  if (!showSearchContainer) {
    return null;
  }

  return (
    <S.SearchContainerRoot isOpen={isOpen}>
      <S.SearchPanelRow>
        <S.SearchPanelItem
          role="presentation"
          onClick={onSetActiveTab('departure')}
          inActive={activeTab !== 'departure' && activeTab !== -1}
        >
          <DepartureContainer
            onClose={closeTab}
            isOpen={activeTab === 'departure'}
            refetchHotels={refetchHotels}
            searchParams={searchParams}
          />
        </S.SearchPanelItem>
      </S.SearchPanelRow>
      <S.SearchPanelRow w="50">
        <S.SearchPanelItem
          role="presentation"
          onClick={onSetActiveTab('pax')}
          inActive={activeTab !== 'pax' && activeTab !== -1}
        >
          <PaxContainer
            onClose={closeTab}
            isOpen={activeTab === 'pax'}
            searchParams={searchParams}
            refetchHotels={refetchHotels}
          />
        </S.SearchPanelItem>
        <S.SearchPanelItem
          role="presentation"
          onClick={onSetActiveTab('duration')}
          inActive={activeTab !== 'duration' && activeTab !== -1}
        >
          <DurationContainer
            onClose={closeTab}
            isOpen={activeTab === 'duration'}
            refetchHotels={refetchHotels}
            searchParams={searchParams}
          />
        </S.SearchPanelItem>
      </S.SearchPanelRow>
      <S.SearchPanelRow>
        <S.SearchPanelItem
          role="presentation"
          onClick={onSetActiveTab('date')}
          inActive={activeTab !== 'date' && activeTab !== -1}
        >
          <DepartureDateContainer
            onClose={closeTab}
            isOpen={activeTab === 'date'}
            refetchHotels={refetchHotels}
            searchParams={searchParams}
          />
        </S.SearchPanelItem>
      </S.SearchPanelRow>

      <S.SearchPanelButtonItem>
        <Button theme="primary" onClick={() => setOpen(false)}>
          {labels?.search}
        </Button>
      </S.SearchPanelButtonItem>

      <S.SearchPanelDescrItem>{shortDescription}</S.SearchPanelDescrItem>
      <S.SearchPanelEditWrapper>
        <Button theme="white" onClick={() => setOpen(true)}>
          {labels?.edit}
        </Button>
      </S.SearchPanelEditWrapper>
    </S.SearchContainerRoot>
  );
};

export default SearchContainer;
