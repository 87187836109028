import { useState } from 'react';
import { updateAllSearchParams } from '../utils/searchParams';
import { useInitialState } from './useInitialState';
import { usePredefined } from './usePredefined';

const filterParameters = [
  'guestRatingFrom',
  'guestRatingTo',
  'distanceMetroTo',
  'priceTo',
  'distanceCenterTo',
  'distanceBeachTo',
  'maxTransfer',
  'filter',
];

const initialSearchParams = {
  p: 1,
  filters: {},
};

export const useLocation = () => {
  const predefined = usePredefined();
  const { location: initalLocation, didServerRenderHotels } = useInitialState();
  const [shouldUpdate, setShouldUpdate] = useState(didServerRenderHotels);

  const [location, setLocation] = useState({
    pathname: initalLocation?.pathname || '',
    searchParams: {
      ...initialSearchParams,
      ...initalLocation?.query,
      ...predefined,
      filters: {
        ...initialSearchParams?.filters,
        ...initalLocation?.query?.filters,
        ...predefined.filters,
      },
    },
  });

  const setSearchParam = (key, value) => {
    const updatedSearchParams = { ...location.searchParams, [key]: value };
    setLocation({ ...location, searchParams: updatedSearchParams });
    updateAllSearchParams(updatedSearchParams);
  };

  const setMultipleSearchParams = (params) => {
    let updatedSearchParams;

    const shouldClearParams =
      Object.keys(params).includes('p') &&
      Object.keys(params).length === 1 &&
      parseInt(params.p, 10) === 1;

    if (shouldClearParams) {
      // all hardcoded parameters are related to the search function
      // and should not be cleared when clearing filters,
      updatedSearchParams = {
        ...initialSearchParams,
        departure: location.searchParams.departure,
        QueryRoomAges: location.searchParams.QueryRoomAges,
        QueryDur: location.searchParams.QueryDur,
        QueryDepDate: location.searchParams.QueryDepDate,
        QueryDepInterval: location.searchParams.QueryDepInterval,
        QueryDepMonths: location.searchParams.QueryDepMonths,
      };
    } else {
      updatedSearchParams = {
        ...location.searchParams,
        ...params,
        filters: {
          ...location.searchParams.filters,
          ...params.filters,
        },
      };

      if (params.QueryDepDate === location.searchParams.QueryDepDate) {
        delete updatedSearchParams.QueryDepDate;
      }

      if (params.QueryDepInterval === null) {
        delete updatedSearchParams.QueryDepInterval;
      }

      if (params.QueryDepMonths) {
        delete updatedSearchParams.QueryDepDate;
        delete updatedSearchParams.QueryDepInterval;
      }
      if (params.QueryDepDate) {
        delete updatedSearchParams.QueryDepMonths;
      }

      const updatedSearchParamsKeys = Object.keys(updatedSearchParams);

      updatedSearchParamsKeys.forEach((key) => {
        if (updatedSearchParams[key] === '') {
          delete updatedSearchParams[key];
        }
      });
    }

    if (!shouldUpdate) {
      setShouldUpdate(true);
    } else {
      setLocation({ ...location, searchParams: updatedSearchParams });
      updateAllSearchParams(updatedSearchParams);
    }

    return updatedSearchParams;
  };

  const hasParams =
    Object.keys(location.searchParams)
      .filter((param) => filterParameters.indexOf(param) !== -1)
      .map((key) => location.searchParams[key])
      .filter((x) => x).length > 0;

  const hasFilterParam = Object.values(location.searchParams.filters).filter((x) => x).length > 0;

  return {
    pathname: location.pathname,
    searchParams: location.searchParams,
    hasFilters: hasFilterParam || hasParams,
    actions: {
      setSearchParam,
      setMultipleSearchParams,
    },
  };
};
