import React, { useMemo, useState, useEffect } from 'react';
import { Map, Marker } from '@bwoty-web/styleguide';
import HotelInfoCard from './HotelInfoCard';
import getCenterZoom from './getCenterZoom';
import SelectedHotel from './SelectedHotel';

const MapView = ({
  hotels,
  closing,
  selectedIndex,
  onSetSelected,
  onSetClosing,
  onClose,
  isLoadingHotels,
  location,
}) => {
  
  const mapDetails = useMemo(() => {
    return getCenterZoom(hotels);
  }, [hotels]);

  const [mapCenter, setMapCenter] = useState(mapDetails.center);

  useEffect(() => {
    setMapCenter(mapDetails.center);
  }, [mapDetails.center]);

  const selectedHotel = hotels?.[selectedIndex];

  if (isLoadingHotels) return null;

  return (
    <Map onClose={onClose} channel="Hotelfinder" center={mapCenter} zoom={mapDetails.zoom}>
      {hotels.map(({ hotel }, i) => {
        const selected = i === selectedIndex && !closing;

        const handleClick = () => {
          onSetSelected(i);
          onSetClosing(false);
          setMapCenter(getCenterZoom([{ hotel }]).center);
        };

        return (
          <Marker
            key={i}
            lat={hotel.content.position.latitude}
            lng={hotel.content.position.longitude}
            selected={selected}
            onClick={handleClick}
            className="tcne-hf-marker"
            animated
          >
            <HotelInfoCard
              hotelName={hotel?.content?.name}
              thumbnail={{ src: hotel?.content?.listImage?.src, alt: hotel?.content?.name }}
              price={hotel?.priceOffer?.totalPriceText}
              discount={hotel?.priceOffer?.ordinaryPrice !== hotel?.priceOffer?.totalPrice}
            />
          </Marker>
        );
      })}
      {selectedHotel ? (
        <SelectedHotel
          id={selectedIndex}
          hotel={selectedHotel}
          onClose={() => onSetSelected(-1)}
          onClosing={() => onSetClosing(true)}
          location={location}
        />
      ) : null}
    </Map>
  );
};

MapView.defaultProps = {
  selectedIndex: -1,
  onSetSelected: () => {},
  onSetClosing: () => {},
  onClose: () => {},
};

export default MapView;
