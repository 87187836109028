import React from 'react';
import { SearchOverlay } from './style';

const Overlay = ({ onClick }) => {
  if (!onClick) {
    return false;
  }
  return <SearchOverlay role="presentation" onClick={onClick} />;
};

export default Overlay;
