const toMucdDictionary = {
  1: 'vs',
  3: 'vn',
  11: 'sd',
  15: 'tf',
  18: 'ds',
  vs: 'vs',
  vn: 'vn',
  sd: 'sd',
  tf: 'tf',
  ds: 'ds',
};

const toSiteIdDictionary = {
  vs: 1,
  vn: 3,
  sd: 11,
  tf: 15,
  ds: 18,
  1: 1,
  3: 3,
  11: 11,
  15: 15,
  18: 18,
};

export const getMucd = (input) => {
  return toMucdDictionary[input];
};

export function toMucd(siteIdOrMucd) {
  if (typeof siteIdOrMucd === 'string') {
    return toMucdDictionary[siteIdOrMucd.toLowerCase()];
  }
  return toMucdDictionary[siteIdOrMucd];
}

export function toSiteId(siteIdOrMucd) {
  if (typeof siteIdOrMucd === 'string') {
    return toSiteIdDictionary[siteIdOrMucd.toLowerCase()];
  }
  return siteIdOrMucd;
}

export default (siteIdOrMucd) => {
  return toMucd(siteIdOrMucd);
};
