import React from 'react';
import * as S from './style';

const ChildCheckboxGroup = ({ className, children, ...rest }) => (
  <S.CheckboxGroup className={className} {...rest}>
    {children}
  </S.CheckboxGroup>
);

export default ChildCheckboxGroup;
