import React from 'react';
import styled from 'styled-components';
import { Button } from '@bwoty-web/styleguide';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { useInitialState } from '../../hooks/useInitialState';

const ClearButton = styled(Button)``;
const UpdateButton = styled(Button)``;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 2.5em;

  @media (min-width: ${deviceWidths.tablet}) {
    ${UpdateButton} {
      display: none;
    }
  }

  ${UpdateButton} {
    margin: 0 auto;
  }

  ${ClearButton} {
    margin: 0 auto;
  }
`;

const FilterFooterContainer = (props) => {
  const { filteredHotelCount, refetchHotels, location } = props;
  const { actions, hasFilters } = location;
  const { umbracoTexts } = useInitialState();

  const handleClear = () => {
    refetchHotels({
      newSearchParams: undefined,
    });
  };

  const handleUpdate = () => {
    actions.setSearchParam('filter', '');
  };

  const showClearButton = hasFilters && umbracoTexts?.labels?.clearFilter;
  const showUpdateButton = umbracoTexts?.labels?.selectFilter;

  return (
    <Container>
      {showClearButton && (
        <ClearButton onClick={handleClear}>{umbracoTexts.labels.clearFilter}</ClearButton>
      )}

      {showUpdateButton && (
        <UpdateButton theme="primary" onClick={handleUpdate}>
          {umbracoTexts.labels.selectFilter.replace('{nrOfHotels}', filteredHotelCount)}
        </UpdateButton>
      )}
    </Container>
  );
};

export default FilterFooterContainer;
