export const getDefaultDeparture = (mucd) => {
  switch (mucd) {
    case 'tf':
      return ['HEL'];
    case 'sd':
      return ['CPH'];
    case 'vn':
      return ['OSL'];
    default:
      return ['ARN'];
  }
};
