import React, { useState } from 'react';
import { Icon, USER_INTERFACE_ARROW_04_DOWN } from '@bwoty-web/styleguide';
import FilterInput from './FilterInput';
import * as S from './style';

const defaultProps = {
  prefix: '',
};
const FilterCollection = ({
  prefix,
  data,
  facetCounts,
  searchParams,
  analytics,
  refetchHotels,
  tripPrice, // This one is only for FilterPrice, should maybe be put in context or something.
}) => {
  const [isExpanded, setExpanded] = useState(data?.expanded?.boolean === true);

  if (!data?.name || !data?.values) {
    return null;
  }

  return (
    <S.FilterCollection>
      <S.FilterCollectionHeader
        $expanded={isExpanded}
        type="button"
        onClick={() => setExpanded(!isExpanded)}
      >
        {data?.name}
        <Icon path={USER_INTERFACE_ARROW_04_DOWN} size="xxs" />
      </S.FilterCollectionHeader>
      <S.FilterCollectionContent $expanded={isExpanded}>
        {Object.keys(data.values)
          .filter((key) => key !== '__meta')
          .map((key) => (
            <FilterInput
              key={key}
              facetKey={key}
              prefix={prefix}
              data={data.values[key]}
              facetCounts={facetCounts}
              searchParams={searchParams}
              analytics={analytics}
              refetchHotels={refetchHotels}
              // Is only used in FilterPrice, no need to send it to all other inputs
              {...(data.values[key]?.facetType === 'price' ? { tripPrice } : {})}
            />
          ))}
      </S.FilterCollectionContent>
    </S.FilterCollection>
  );
};

FilterCollection.defaultProps = defaultProps;

export default FilterCollection;
