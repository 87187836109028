import React, { useEffect, useState } from 'react';
import { Button } from '@bwoty-web/styleguide';
import DepartureBody from './DepartureBody';
import { SelectedValue } from './SelectedValue';
import Overlay from './SearchOverlay';
import * as S from './style';
import { getDefaultDeparture } from '../../../server/util/departureHelper';
import { useMucd } from '../../hooks/useMucd';
import { useInitialState } from '../../hooks/useInitialState';
import { useDepartures } from '../../hooks/useDepartures';

const setVhVariable = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const DepartureContainer = (props) => {
  const { searchParams, refetchHotels, onClose, isOpen } = props;

  const mucd = useMucd();
  const { allDepartures, umbracoTexts } = useInitialState();
  const { labels: texts } = umbracoTexts;
  const { selectedDepartures: preSelectedDepartures } = useDepartures(searchParams);

  const [selectedDepartures, setSelectedDepartures] = useState([]);

  useEffect(() => {
    setSelectedDepartures(preSelectedDepartures.slice());
    setVhVariable();
    window.addEventListener(
      'resize',
      () => {
        setVhVariable();
      },
      false
    );
  }, [preSelectedDepartures]);

  const updateDepartures = (e, name) => {
    const deps = selectedDepartures;
    const depIndex = deps.indexOf(name);
    if (depIndex === -1) {
      deps.push(name);
    } else {
      deps.splice(depIndex, 1);
    }
    setSelectedDepartures([...deps]);
  };

  const close = (e) => {
    if (selectedDepartures.length < 1) {
      setSelectedDepartures([...getDefaultDeparture(mucd)]);
    }
    refetchHotels({ newSearchParams: { departure: selectedDepartures.join(',') } });
    onClose(e);
  };

  const submit = (e) => {
    e.stopPropagation();
    close(e);
  };

  if (!allDepartures || !allDepartures.length) {
    return null;
  }

  const selectedDeparturesText = selectedDepartures
    .map((dep) => (allDepartures.find((dd) => dd.key === dep) || {}).value)
    .join(', ');

  return (
    <S.DepartureSelector>
      <SelectedValue label={texts.departureSelect} isOpen={isOpen}>
        {selectedDeparturesText}
      </SelectedValue>
      <S.SearchOptionDropDownWrapper styleOpen={isOpen}>
        <S.SearchOptionDropDown close={close} closed={!isOpen} header={texts.departurePopupHeader}>
          <S.SearchOptionDropDownInner>
            <S.SearchOptionDropDownBody>
              <DepartureBody
                departures={allDepartures}
                selectedDepartures={selectedDepartures}
                onSelect={updateDepartures}
                isOpen={isOpen}
              />
            </S.SearchOptionDropDownBody>
            <S.SearchOptionDropDownFooter>
              <S.SearchOptionDropDownButtonWrap>
                <Button
                  theme="primary"
                  className="tcne-hh-search-filter-update"
                  onClick={submit}
                  disabled={!selectedDepartures.length}
                >
                  {texts.paxConfirm}
                </Button>
              </S.SearchOptionDropDownButtonWrap>
            </S.SearchOptionDropDownFooter>
          </S.SearchOptionDropDownInner>
        </S.SearchOptionDropDown>
      </S.SearchOptionDropDownWrapper>
      {isOpen && <Overlay onClick={close} />}
    </S.DepartureSelector>
  );
};

export default DepartureContainer;
