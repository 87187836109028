import React from 'react';
import styled from 'styled-components';
import { Icon, USER_INTERFACE_ARROW_01 } from '@bwoty-web/styleguide';
import { formatDate } from './utils';
import { useMucd } from '../../hooks/useMucd';

const RangeIcon = styled(Icon)`
  max-height: 1em;
  margin: 0 4px;
`;

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getMonthLabel = (monthStr, monthNames) => {
  const date = new Date(`${monthStr.substr(0, 4)}-${monthStr.substr(4, 6)}`);
  return `${capitalize(monthNames[date.getMonth()])} ${date.getFullYear()}`;
};

export const DatePickerSelectedValue = ({
  emptyLabel,
  monthNames,
  selectedDate,
  selectedMonths,
  selectedInterval,
}) => {
  const mucd = useMucd();

  if (!selectedDate && (selectedMonths || []).length === 0) {
    return emptyLabel;
  }

  if (selectedMonths.length > 0) {
    const sortedMonths = selectedMonths.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    return sortedMonths.map((monthStr) => getMonthLabel(monthStr, monthNames)).join(', ');
  }

  if (selectedInterval && selectedInterval.to && selectedInterval.from) {
    return (
      <span>
        {formatDate(new Date(selectedInterval.from), mucd)}{' '}
        <RangeIcon size="xxs" path={USER_INTERFACE_ARROW_01} />{' '}
        {formatDate(new Date(selectedInterval.to), mucd)}
      </span>
    );
  }

  const value = formatDate(new Date(selectedDate), mucd);

  if (value) {
    return value;
  }

  return null;
};

DatePickerSelectedValue.defaultProps = {
  selectedDate: undefined,
};
