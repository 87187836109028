import React, { useState } from 'react';
import { USER_INTERFACE_ARROW_04_DOWN } from '@bwoty-web/styleguide';
import Checkbox from './Checkbox';
import * as S from './style';

const CheckboxWithInfo = ({ description, children, ...rest }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!isOpen);
  };

  if (!description) {
    return <Checkbox {...rest}>{children}</Checkbox>;
  }

  return (
    <div>
      <Checkbox {...rest}>
        <S.StyledCheckboxInfoLink $open={isOpen} onClick={toggle}>
          <S.StyledCheckboxInfoLinkLabel>{children}</S.StyledCheckboxInfoLinkLabel>
          <S.StyledCheckboxInfoArrow path={USER_INTERFACE_ARROW_04_DOWN} size="xxxs" />
        </S.StyledCheckboxInfoLink>
      </Checkbox>
      {isOpen && <S.StyledCheckboxInfoDescr>{description}</S.StyledCheckboxInfoDescr>}
    </div>
  );
};

export default CheckboxWithInfo;
