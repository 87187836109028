import React from 'react';
import styled from 'styled-components';
import { ToggleButton } from '@bwoty-web/styleguide';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';

const StyledToggleButton = styled(ToggleButton)``;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  padding-bottom: 12px;

  @media (min-width: ${deviceWidths.tablet}) {
    ${StyledToggleButton} {
      margin-right: 10px;
    }
  }
`;

export const IntervalSelection = ({ selected, dayLabel, daysLabel, onSelect }) => {
  return (
    <Container>
      <StyledToggleButton selected={selected === 1} onClick={() => onSelect(1)}>
        +/- 1 {dayLabel}
      </StyledToggleButton>
      <StyledToggleButton selected={selected === 2} onClick={() => onSelect(2)}>
        +/- 2 {daysLabel}
      </StyledToggleButton>
      <StyledToggleButton selected={selected === 3} onClick={() => onSelect(3)}>
        +/- 3 {daysLabel}
      </StyledToggleButton>
    </Container>
  );
};
