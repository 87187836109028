import { useEffect, useState, useCallback } from 'react';

import { getDetails } from '../api';

import { deleteSearchParam, updateOrAddSearchParam } from '../utils/searchParams';
import { useInitialState } from './useInitialState';
import { useMucd } from './useMucd';
import { useUmbracoTexts } from './useUmbracoTexts';

export const usePriceDetails = (hotelObject, clearHotel) => {
  const mucd = useMucd();
  const key = hotelObject?.priceSpecificationUrl;
  const { clientOrigoUrl } = useInitialState();

  const { useNewPriceDetails: unpd } = useUmbracoTexts();
  const { boolean: useNewPriceDetails = true } = unpd;

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [priceDetails, setPriceDetails] = useState();
  const [priceDetailsTexts, setPriceDetailsTexts] = useState();

  const getPriceDetails = useCallback(() => {
    setLoading(true);
    getDetails(key, { mucd, fetchTexts: true, useNewPriceDetails, origoUrl: clientOrigoUrl })
      .then((res) => {
        setPriceDetails(res.priceDetails);
        setPriceDetailsTexts(res.priceSpec);

        setLoading(false);
      })
      .catch(setError);
  }, [key, mucd, useNewPriceDetails]);

  const clearPriceDetails = () => {
    clearHotel();
    setPriceDetails(null);
    setPriceDetailsTexts(null);
    setError(null);
    deleteSearchParam('details');
  };

  useEffect(() => {
    if (!hotelObject) return;
    updateOrAddSearchParam('details', hotelObject?.wvId);
    getPriceDetails();
  }, [hotelObject, getPriceDetails]);

  return {
    priceDetails,
    priceDetailsTexts,
    isLoading,
    error,
    actions: {
      clearPriceDetails,
      getPriceDetails,
    },
  };
};
