export const distanceCenterFiltermap = {
  25: '_d0-d1',
  50: '_d0-d1',
  100: '_d0-d1',
  250: '_d0-d2',
  500: '_d0-d3',
  750: '_d0-d4',
  1000: '_d0-d5',
  2000: '_d0-d6',
  3000: '_d0-d7',
  4000: '_d0-d8',
  5000: '_d0-d9',
  10000: '_d0-d10',
};

export const distanceBeachMap = {
  25: '_b0-b1',
  50: '_b0-b2',
  100: '_b0-b3',
  250: '_b0-b4',
  500: '_b0-b5',
  750: '_b0-b6',
  1000: '_b0-b7',
  2000: '_b0-b8',
  3000: '_b0-b9',
  4000: '_b0-b10',
  5000: '_b0-b11',
  10000: '_b0-b12',
};

export const distanceMetroFilterMap = {
  25: '_l0-l1',
  50: '_l0-l2',
  100: '_l0-l3',
  250: '_l0-l4',
  500: '_l0-l5',
  750: '_l0-l6',
  1000: '_l0-l7',
  2000: '_l0-l8',
  3000: '_l0-l9',
  4000: '_l0-l10',
  5000: '_l0-l11',
  10000: '_l0-l12',
};

export const CONVERT_DURATION_DICT = {
  8: 1,
  15: 2,
  22: 3,
  29: 4,
  33: 'l',
  5: 's',
};

export const MARKET_UNIT_DICTIONARY = {
  vs: 'S',
  vn: 'N',
  sd: 'D',
  tf: 'F',
  ds: 'G',
};

export const DURATION_DICTIONARY = {
  1: 8,
  2: 15,
  3: 22,
  4: 29,
  s: 5,
  l: 33,
};
