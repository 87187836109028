import React, { useMemo } from 'react';

export const InitialStateContext = React.createContext();

const InitialStateProvider = ({ children, initialState }) => {
  const memoValue = useMemo(
    () => ({
      siteId: initialState.utils.siteId,
      mucd: initialState.utils.mucd,
      geoId: initialState.utils.geoId,
      headers: initialState.utils.headers,
      defaults: initialState.utils.defaults,
      predefined: initialState.predefined,
      prepare: initialState.prepare,
      umbracoTexts: initialState.umbracoTexts,
      text: initialState.text,
      featureToggles: initialState.featureToggles,
      location: initialState.location,
      analytics: initialState.analytics,
      hotels: initialState.hotels,
      didServerRenderHotels: initialState.didServerRenderHotels,
      imageUrl: initialState.utils.imageUrl,
      clientOrigoUrl: initialState.clientOrigoUrl,
    }),
    [
      initialState.utils.siteId,
      initialState.utils.mucd,
      initialState.utils.geoId,
      initialState.utils.headers,
      initialState.utils.defaults,
      initialState.utils.imageUrl,
      initialState.predefined,
      initialState.prepare,
      initialState.umbracoTexts,
      initialState.text,
      initialState.featureToggles,
      initialState.location,
      initialState.analytics,
      initialState.hotels,
      initialState.didServerRenderHotels,
      initialState.clientOrigoUrl,
    ]
  );

  return <InitialStateContext.Provider value={memoValue}>{children}</InitialStateContext.Provider>;
};

export default InitialStateProvider;
