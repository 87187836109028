import React, { createContext, useContext, useMemo } from 'react';
import { useLocation } from '../hooks/useLocation';

const LocationContext = createContext();

function LocationProvider({ children }) {
  const location = useLocation();
  return <LocationContext.Provider value={location}>{children}</LocationContext.Provider>;
}

export const useLocationContext = () => useContext(LocationContext);
export default LocationProvider;
