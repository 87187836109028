import React, { useEffect, useState } from 'react';
import { Button } from '@bwoty-web/styleguide';
import { SelectedValue } from './SelectedValue';
import PaxRow from './PaxRow';
import PaxChildrenContainer from './PaxChildrenContainer';
import Overlay from './SearchOverlay';
import * as S from './style';
import { pluralize } from '../details/utils';
import { usePax } from '../../hooks/usePax';
import { useInitialState } from '../../hooks/useInitialState';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const getChildAges = (pax) => {
  const initChilds = {};
  let count = 0;
  pax.forEach((pp) => {
    if (pp < 42) {
      initChilds[count] = pp;
      count++;
    }
  });
  return initChilds;
};

const PaxContainer = (props) => {
  const { searchParams, refetchHotels, onClose, isOpen } = props;

  const { umbracoTexts, featureToggles } = useInitialState();
  const { labels: texts } = umbracoTexts;

  const { roomAges = [], pax: currentPax } = usePax(searchParams);

  const [state, setState] = useState({
    nrOfAdults: 2,
    nrOfChildren: 0,
    childAges: {},
  });

  useEffect(() => {
    if (roomAges.length === 0) return;
    setState({
      nrOfAdults: roomAges.filter((pp) => pp >= 42).length,
      nrOfChildren: roomAges.filter((pp) => pp < 42).length,
      childAges: getChildAges(roomAges),
    });
  }, [roomAges]);

  const getPaxString = () => {
    return [
      pluralize(state.nrOfAdults, texts?.adult, texts?.adults),
      pluralize(state.nrOfChildren, texts?.child, texts?.children),
    ]
      .filter((x) => x)
      .join(', ');
  };

  const addPax = (increment, isChildren) => {
    const MAX_NR_OF_PAX = featureToggles.showEightPax ? 8 : 6;

    const newState = {
      nrOfAdults: state.nrOfAdults,
      nrOfChildren: state.nrOfChildren,
    };

    if (newState.nrOfAdults + newState.nrOfChildren >= MAX_NR_OF_PAX && increment > 0) {
      return;
    }
    if (isChildren && newState.nrOfChildren + increment >= 0) {
      if (increment < 0) {
        newState.childAges = state.childAges;
        delete newState.childAges[newState.nrOfChildren - 1];
      }
      newState.nrOfChildren += increment;
    } else if (!isChildren && newState.nrOfAdults + increment > 0) {
      newState.nrOfAdults += increment;
    }

    setState({
      ...state,
      ...newState,
    });
  };

  const canConfirm = () => {
    // Så länge man har valt minst 1 vuxen och har valt åldrar på sina barn så är de bara o köra..
    let isValid = state.nrOfAdults > 0;

    for (let index = 0; index < state.nrOfChildren; index++) {
      if (!state.childAges[index]) {
        isValid = false;
      }
    }

    return isValid;
  };

  const updateChildValue = (value, index) => {
    const { childAges } = state;
    childAges[index] = value;

    if (canConfirm()) {
      setState({
        ...state,
        invalidChildrenAges: false,
      });
    }

    setState({
      ...state,
      childAges,
    });
  };

  const close = (e, reason) => {
    if (reason === 'closeOnCross') {
      const paxString = currentPax;
      const paxCombo = paxString.split('_');
      const nrOfAdults = parseInt(paxCombo[0], 10);
      const nrOfChildren = parseInt(paxCombo[1], 10);
      setState({ ...state, nrOfAdults, nrOfChildren });
      onClose(e);
      return;
    }

    const queryRoomAges = [...Array(state.nrOfAdults).keys()].map(() => 42);
    const { nrOfChildren } = state;

    if (nrOfChildren) {
      for (let i = 0; i < nrOfChildren; i++) {
        queryRoomAges.push(state.childAges[i]);
      }
    }

    const hasChanged =
      roomAges.length !== queryRoomAges.length ||
      !!roomAges.filter((pp, index) => parseInt(queryRoomAges[index], 10) !== parseInt(pp, 10))
        .length;

    if (reason === 'clickOutside' && !canConfirm()) {
      setState({
        ...state,
        invalidChildrenAges: true,
      });
    } else {
      if (hasChanged) {
        refetchHotels({
          newSearchParams: { QueryRoomAges: queryRoomAges.join(',') },
          analyticsData: { type: ANALYTICS_DATA_TYPES.SEARCH },
        });
      }
      setState({
        ...state,
        invalidChildrenAges: false,
      });
      onClose(e);
    }
  };

  const numberOfPax = featureToggles.showEightPax ? 7 : 5;
  const totalNrOfPax = state.nrOfAdults + state.nrOfChildren;

  return (
    <S.PaxSelector>
      <SelectedValue label={texts?.paxSelect} isOpen={isOpen}>
        {getPaxString()}
      </SelectedValue>
      <S.PaxSelectorPopup
        close={(e) => close(e, 'closeOnCross')}
        closed={!isOpen}
        header={texts.paxLabel}
      >
        <S.PaxSelectorBody>
          <S.PaxSelectorOptionsWrap>
            <PaxRow
              label={state.nrOfAdults > 1 ? texts.adults : texts.adult}
              info={texts.adultInfo}
              amount={state.nrOfAdults}
              addPax={addPax}
              isAddDisabled={totalNrOfPax > numberOfPax}
              isRemoveDisabled={state.nrOfAdults === 1}
              data-test="hf-s-pax-adult"
            />
            <PaxRow
              label={state.nrOfChildren > 1 ? texts.children : texts.child}
              info={texts.childInfo}
              amount={state.nrOfChildren}
              addPax={addPax}
              isAddDisabled={totalNrOfPax > numberOfPax}
              isRemoveDisabled={state.nrOfChildren < 1}
              isChildren
              data-test="hf-s-pax-child"
            />
            <PaxChildrenContainer
              texts={texts}
              amount={state.nrOfChildren}
              childAges={state.childAges}
              updateChildValue={updateChildValue}
              invalidChildrenAges={state.invalidChildrenAges}
            />
          </S.PaxSelectorOptionsWrap>
          <S.PaxSelectorButtonWrap>
            <Button
              theme="primary"
              onClick={close}
              disabled={!canConfirm()}
              data-test="hf-s-pax-submit"
            >
              {texts.paxConfirm}
            </Button>
          </S.PaxSelectorButtonWrap>
        </S.PaxSelectorBody>
      </S.PaxSelectorPopup>
      {isOpen && <Overlay onClick={(e) => close(e, 'clickOutside')} />}
    </S.PaxSelector>
  );
};

export default PaxContainer;
