import { useMemo } from 'react';
import { getDefaultDeparture } from '../../server/util/departureHelper';
import { useDefaultSearchQuery } from './useDefaultSearchQuery';
import { useInitialState } from './useInitialState';
import { useMucd } from './useMucd';

export const getSelectedDepartures = (searchParams, defaults, allDepartures, mucd) => {
  if (!searchParams.departure) {
    if (defaults.departureId) {
      const fromDefaults = allDepartures.find((dep) => dep.caId === defaults.departureId);
      if (fromDefaults) {
        return [fromDefaults.key];
      }
    }
  } else {
    return searchParams?.departure?.split(',') || [];
  }
  return getDefaultDeparture(mucd);
};

export const getDepartureString = (selectedDepartures) => {
  if (selectedDepartures.indexOf('ARN') !== -1) {
    return [...selectedDepartures, 'STO'].join(',');
  }
  return selectedDepartures.join(',');
};

export const getSelectedDeparturesOption = (selectedDepartures, allDepartures) => {
  return selectedDepartures.map((departure) => {
    return allDepartures.find((dep) => dep.key === departure) || { name: departure, caId: -1 };
  });
};

export const useDepartures = (searchParams) => {
  const mucd = useMucd();
  const defaults = useDefaultSearchQuery();
  const { allDepartures } = useInitialState();

  const selectedDepartures = useMemo(
    () => getSelectedDepartures(searchParams, defaults, allDepartures, mucd),
    [allDepartures, defaults, mucd, searchParams]
  );
  const departureString = useMemo(
    () => getDepartureString(selectedDepartures),
    [selectedDepartures]
  );
  const selectedDeparturesOption = useMemo(
    () => getSelectedDeparturesOption(selectedDepartures, allDepartures),
    [allDepartures, selectedDepartures]
  );

  return {
    departureString,
    selectedDepartures,
    allDepartures,
    selectedDeparturesOption,
  };
};
