import { css } from "styled-components";

export const cssFadeIn = css`
  animation-name: fadeIn;
  animation-duration: 0.2s;

  @keyframes fadeIn {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}
`;