export const getCloudinaryUrl = (imageDomain, imagePath, height, width, format = 'jpg', focalX, focalY, dpr) => {
    const focalPoint = (focalX && focalY) ? `g_xy_center,x_${focalX},y_${focalY}` : 'g_center';
    const parameters = `c_fill,w_${width},h_${height},f_${format},${focalPoint},q_auto:eco`;

    if (dpr) return `${imageDomain}/image/upload/${parameters}${imagePath}, ${imageDomain}/image/upload/${parameters},dpr_${dpr}.0${imagePath} ${dpr}x`;

    return `${imageDomain}/image/upload/${parameters}${imagePath}`;
  };
  
  export const getCloudinarySources = (mediaString, imageDomain, imagePath, height, width, focalX, focalY, dpr) =>
    [
      {
        media: mediaString,
        srcSet: getCloudinaryUrl(imageDomain, imagePath, height, width, 'avif', focalX, focalY, dpr),
        type: 'image/avif',
      },
      {
        media: mediaString,
        srcSet: getCloudinaryUrl(imageDomain, imagePath, height, width, 'jp2', focalX, focalY, dpr),
        type: 'image/jp2',
      },
      {
        media: mediaString,
        srcSet: getCloudinaryUrl(imageDomain, imagePath, height, width, 'webp', focalX, focalY, dpr),
        type: 'image/webp',
      },
      {
        media: mediaString,
        srcSet: getCloudinaryUrl(imageDomain, imagePath, height, width, 'jpg', focalX, focalY, dpr),
        type: 'image/jpeg',
      },
    ];

  export const getDeviceSources = (deviceSpecs, imageDomain, imagePath, focalX, focalY, dpr) => {
    const deviceSources = [];

    deviceSpecs.forEach((deviceSpec) => {
      const { mediaString, size: { height, width } } = deviceSpec;
      deviceSources.push(getCloudinarySources(mediaString, imageDomain, imagePath, height, width, focalX, focalY, dpr));
    });

    return deviceSources.flat();
  }