import { useEffect, useState, useCallback } from 'react';

import { useInitialState } from './useInitialState';
import { useDepartures } from './useDepartures';
import { useDepartureDate } from './useDepartureDate';
import { usePax } from './usePax';

import {
  getFilterEvent as getFilterEventOverride,
  getSearchEvent as getSearchEventOverride,
  getProductImpression as getProductImpressionOverride,
  getTravelHotelFinderHits,
  getTravelType,
  getTravelFilterSet,
  getSortEvent,
} from '../utils/analytics';

export const ANALYTICS_DATA_TYPES = {
  SEARCH: 'search',
  FILTER: 'filter',
  SORT: 'sort',
};

export const initialAnalyticsData = {
  params: {},
  type: undefined, // string -> search or filter
  shouldSendEvent: false,
};

export const logToDataLayer = (data) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(data);
};

export const useAnalytics = (hotelsResult, { searchParams }) => {
  const initialState = useInitialState();
  const [analyticsData, setAnalyticsData] = useState(initialAnalyticsData);

  const { pax } = usePax(searchParams);

  const { selectedDepartures, selectedDeparturesOption } = useDepartures(searchParams);
  const { departureDateInterval: offset } = useDepartureDate(searchParams);

  const getSearchEvent = useCallback(
    () =>
      getSearchEventOverride(
        initialState.meta,
        selectedDepartures,
        pax,
        hotelsResult.totalCount,
        getTravelType(initialState?.umbracoTexts?.__meta?.alias),
        searchParams,
        getTravelHotelFinderHits(hotelsResult.entities),
        offset
      ),
    [
      hotelsResult.entities,
      hotelsResult.totalCount,
      initialState.meta,
      initialState?.umbracoTexts?.__meta?.alias,
      offset,
      pax,
      searchParams,
      selectedDepartures,
    ]
  );

  const getFilterEvent = useCallback(
    () =>
      getFilterEventOverride(
        hotelsResult.count,
        getSearchEvent(),
        getTravelFilterSet(initialState?.umbracoTexts?.__meta?.alias),
        getTravelHotelFinderHits(hotelsResult.entities)
      ),
    [
      getSearchEvent,
      hotelsResult.count,
      hotelsResult.entities,
      initialState?.umbracoTexts?.__meta?.alias,
    ]
  );

  const getProductImpression = useCallback(
    () =>
      getProductImpressionOverride(
        initialState?.analytics?.ids,
        hotelsResult.ids,
        hotelsResult.entities,
        getTravelType(initialState?.umbracoTexts?.__meta?.alias),
        selectedDeparturesOption,
        initialState?.meta
      ),
    [
      hotelsResult.entities,
      hotelsResult.ids,
      initialState?.analytics?.ids,
      initialState?.meta,
      initialState?.umbracoTexts?.__meta?.alias,
      selectedDeparturesOption,
    ]
  );

  const setData = ({ params = {}, shouldSendEvent = true, type }) => {
    setAnalyticsData({ params, shouldSendEvent, type });
  };

  const resetData = () => {
    setAnalyticsData(initialAnalyticsData);
  };

  useEffect(() => {
    if (hotelsResult.ids.length === 0) return;
    logToDataLayer(getProductImpression());

    if (!analyticsData.shouldSendEvent) return;

    switch (analyticsData.type) {
      case ANALYTICS_DATA_TYPES.SEARCH:
        logToDataLayer(getSearchEvent());
        break;
      case ANALYTICS_DATA_TYPES.FILTER:
        logToDataLayer({ ...analyticsData.params, ...getFilterEvent() });
        break;
      case ANALYTICS_DATA_TYPES.SORT:
        logToDataLayer(getSortEvent(analyticsData.params.value));
        break;
      default:
        return;
    }

    resetData();
  }, [
    analyticsData.params,
    analyticsData.shouldSendEvent,
    analyticsData.type,
    getFilterEvent,
    getProductImpression,
    getSearchEvent,
    hotelsResult,
  ]);

  return {
    getSearchEvent,
    getFilterEvent,
    getProductImpression,
    setAnalyticsData: setData,
  };
};
