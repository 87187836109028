import React from 'react';
import Checkbox from './Checkbox';
import ChildCheckboxGroup from './ChildCheckboxGroup';
import { useInitialState } from '../../hooks/useInitialState';
import transformKey from '../../utils/transformKey';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const FilterGeographicalEntryDumb = (props) => {
  const { geoId, counts, prefix, isChild, searchParams, refetchHotels } = props;
  const { filters = {} } = searchParams;

  const { geographicalDictionary } = useInitialState();

  const geographicalEntry = geographicalDictionary[geoId];

  const handleChange = (e) => {
    refetchHotels({
      newSearchParams: {
        filters: {
          ...geographicalEntry.resetTree,
          [geographicalEntry.key]: !!counts?.[geographicalEntry.key] && e.target.checked,
        },
      },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        shouldSendEvent: !!counts?.[geographicalEntry.key] && e.target.checked,
        params: {
          travelFilterCategory: transformKey(prefix.split('.')[0], isChild),
          travelFilterValue: geographicalEntry.description,
        },
      },
    });
  };

  if (
    !geographicalEntry ||
    ((counts?.[geographicalEntry.key] || 0) === 0 && !filters[geographicalEntry.key])
  ) {
    return false;
  }

  return (
    <div>
      <Checkbox
        key={geographicalEntry.key}
        checked={filters[geographicalEntry.key]}
        onChange={handleChange}
        uniqueId={`${prefix}${geographicalEntry.key}`}
        disabled={!counts?.[geographicalEntry.key] && !filters[geographicalEntry.key]}
      >
        {geographicalEntry.description} ({counts?.[geographicalEntry.key] || 0})
      </Checkbox>
      {(filters[geographicalEntry.key] ||
        geographicalEntry.showOnSelected.find((key) => filters[key])) && (
        <ChildCheckboxGroup>
          {geographicalEntry.children.map((child) => (
            <FilterGeographicalEntry
              geoId={child}
              key={child}
              prefix={`${prefix}${geographicalEntry.key}`}
              counts={counts}
              searchParams={searchParams}
              refetchHotels={refetchHotels}
              isChild
            />
          ))}
        </ChildCheckboxGroup>
      )}
    </div>
  );
};

FilterGeographicalEntryDumb.defaultProps = {
  prefix: '',
  isChild: false,
};

const FilterGeographicalEntry = FilterGeographicalEntryDumb;

export default FilterGeographicalEntry;
