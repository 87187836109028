export default function normalizeUnits(array, idSelector = (item) => item.id) {
  const ids = [];
  const entities = {};
  array.forEach((entity) => {
    const id = idSelector(entity);
    ids.push(id);
    entities[id] = entity;
  });
  return {
    ids,
    entities,
  };
}
