import React from 'react';
import url from 'url';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const Link = (props) => {
  const { location, onClick, className, style, gtmAttribute, children } = props;
  const { pathname, searchParams = {}, actions } = location;
  const { queryUpdate } = props;

  const handleOnClick = (event) => {
    if (event.defaultPrevented || isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    event.preventDefault();
    if (onClick) {
      onClick(event);
    } else {
      actions.setMultipleSearchParams(queryUpdate);
    }
  };

  const getHref = () => {
    const query = {
      ...searchParams,
      ...queryUpdate,
    };
    const filteredQuery = {};
    Object.keys(query).forEach((key) => {
      if (key !== '' && key !== undefined && key !== null) {
        filteredQuery[key] = query[key];
      }
    });
    return url.format({
      pathname: pathname || '',
      query: filteredQuery,
    });
  };

  if ((onClick && typeof onClick !== 'function') || (!onClick && !queryUpdate)) {
    return false;
  }
  return (
    <a
      href={getHref()}
      onClick={handleOnClick}
      className={className}
      style={style}
      data-gtm={gtmAttribute}
      rel="nofollow"
    >
      {children}
    </a>
  );
};

export const LinkDumb = Link;
export default Link;
