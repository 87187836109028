import gql from 'graphql-tag';
import { fragment as mapFragment } from '../components/map/MapContainer';
import { fragment as hotelContentFragment } from '../components/HotelContainer';
import { hotelFragment } from '../components/hotelhit/fragments';

export const hotelQuery = gql`
  query MoreHotelsQuery($key: String!, $paxAges: [Int], $isInvalidPax: Boolean) {
    hotelFinderHotels(key: $key, paxAges: $paxAges, isInvalidPax: $isInvalidPax) {
      messages {
        type
        header
        message
      }
      count
      totalCount
      hotels {
        wvId
        priceSpecificationUrl
        isIndependent
        hotel {
          ...HotelHitOrigoHotel
          ...MapContainerHotel
          ...HotelContainerHotel
        }
      }
      tripPrice {
        minPrice
        maxPrice
        prefix
      }
      facets {
        key
        count
      }
    }
  }
  ${hotelFragment}
  ${mapFragment}
  ${hotelContentFragment}
`;
