import React from 'react';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { Picture, LoadBlock } from '@bwoty-web/styleguide';
import styled from 'styled-components';
import { cssFadeIn } from '../../utils/cssFadeIn';
import { useInitialState } from '../../hooks/useInitialState';
import PriceDetailsInfoLoader from './PriceDetailsInfoLoader';
import PriceDetailsInfoTable from './PriceDetailsInfoTable';
import { getDeviceSources } from '../../utils/imageUtils';

import { pluralize } from './utils';

const PriceDetailsInfoWrapper = styled.div`
  @media (min-width: ${deviceWidths.tablet}) {
    display: flex;
    gap: 30px;
  }
`;

const PriceDetailsInfoImage = styled(Picture)`
  && {
    ${cssFadeIn}
    
    @media (max-width: ${deviceWidths.tabletMax}) {
      width: 100%;
    }
    @media (min-width: ${deviceWidths.tablet}) {
      flex: 0 0 34%;
      height: auto;
    }

    img {
      width: 100%;
    }
  }
`;

const PriceDetailsInfoImageLoader = styled(LoadBlock)`
  && {
    @media (max-width: ${deviceWidths.tabletMax}) {
      width: 100%;
    }
    @media (min-width: ${deviceWidths.tablet}) {
      align-self: baseline;
      width: 285px;
      height: 285px;
    }
  }
`;

const PriceDetailsInfoText = styled.div`
  @media (max-width: ${deviceWidths.tabletMax}) {
    margin-top: 12px;
    width: 100%;
  }

  @media (min-width: ${deviceWidths.tablet}) {
    flex: 1 1 auto;
  }
`;

const PriceDetailsInfoHeader = styled.h3`
  ${cssFadeIn}
`;

const PriceDetailsInfo = ({ details, isLoading, hotel, priceDetailsTexts }) => {
  const { imageUrl: imageDomain } = useInitialState();

  if (!details && !isLoading) {
    return null;
  }

  const relativeUrl = hotel?.content?.listImage?.relativeUrl;

  const mobileImageSize = { width: '365', height: '365' };
  const desktopImageSize = { width: '285', height: '285' };

  const deviceSpecs = [
    {
      size: mobileImageSize,
      mediaString: `(max-width: ${deviceWidths.tabletMax})`,
    },
    {
      size: desktopImageSize,
      mediaString: `(min-width: ${deviceWidths.tablet})`,
    },
  ];

  const imgSources = getDeviceSources(deviceSpecs, imageDomain, relativeUrl, null, null, 2);

  const pax = [
    pluralize(details?.numberOfAdults, priceDetailsTexts?.adult, priceDetailsTexts?.adults),
    pluralize(details?.numberOfChildren, priceDetailsTexts?.child, priceDetailsTexts?.children),
  ]
    .filter((x) => x)
    .join(', ');

  return (
    <PriceDetailsInfoWrapper>
      {isLoading ? <PriceDetailsInfoImageLoader /> : <PriceDetailsInfoImage sources={imgSources} />}

      <PriceDetailsInfoText>
        {isLoading ? (
          <PriceDetailsInfoLoader />
        ) : (
          <>
            <PriceDetailsInfoHeader>{hotel?.content?.name}</PriceDetailsInfoHeader>
            {!!details && (
              <PriceDetailsInfoTable
                priceDetailsTexts={priceDetailsTexts}
                pax={pax}
                details={details}
                hotel={hotel}
              />
            )}
          </>
        )}
      </PriceDetailsInfoText>
    </PriceDetailsInfoWrapper>
  );
};

export default PriceDetailsInfo;
