import React from 'react';
import { USER_INTERFACE_CIRCLE_PLUS_ADD, USER_INTERFACE_CIRCLE_MINUS_DELETE } from '../iconPaths';
import * as S from './style';

const PaxRow = ({
  label,
  info,
  amount,
  addPax,
  isChildren,
  isRemoveDisabled,
  isAddDisabled,
  ...other
}) => {
  const testId = other['data-test'];

  return (
    <S.PaxPopupRow>
      <S.PaxPopupRowLeft>
        <S.PaxPopupRowAmount>{amount}</S.PaxPopupRowAmount>
        <S.PaxPopupRowInfo>
          <S.PaxPopupRowInfoTitle>{label}</S.PaxPopupRowInfoTitle>
          <S.PaxPopupRowInfoSubTitle>{info}</S.PaxPopupRowInfoSubTitle>
        </S.PaxPopupRowInfo>
      </S.PaxPopupRowLeft>

      <S.PaxPopupRowRight>
        <S.PaxPopupButton
          disabled={isRemoveDisabled}
          onClick={() => addPax(-1, isChildren)}
          data-test={`${testId}-remove`}
        >
          <S.PaxPopupButtonIcon path={USER_INTERFACE_CIRCLE_MINUS_DELETE} />
        </S.PaxPopupButton>
        <S.PaxPopupButton
          disabled={isAddDisabled}
          onClick={() => addPax(1, isChildren)}
          data-test={`${testId}-add`}
        >
          <S.PaxPopupButtonIcon path={USER_INTERFACE_CIRCLE_PLUS_ADD} />
        </S.PaxPopupButton>
      </S.PaxPopupRowRight>
    </S.PaxPopupRow>
  );
};

export default PaxRow;
