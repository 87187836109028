import React from 'react';

const useEnhancedEffect =
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'test'
    ? React.useLayoutEffect
    : React.useEffect;

export function NoSSR(props) {
  const { children, fallback = null } = props;
  const [isMounted, setMounted] = React.useState(false);

  useEnhancedEffect(() => {
    setMounted(true);
  }, []);

  if (isMounted) {
    return children;
  }

  return fallback;
}
