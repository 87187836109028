import { useMemo } from 'react';
import { usePreparedData } from './usePreparedData';

const generateMonthsString = (months) => months.map((m) => `m${m}`).join(',');

export const getMonths = (searchParams, allMonths) => {
  const selectedMonths = (searchParams.QueryDepMonths || '').split(',').filter((x) => x);
  if (selectedMonths.length === 0) {
    return [[], generateMonthsString(allMonths)];
  }
  return [selectedMonths, generateMonthsString(selectedMonths)];
};

export const useMonths = (searchParams) => {
  const { allMonths } = usePreparedData();
  const [months, monthsString] = useMemo(
    () => getMonths(searchParams, allMonths || []),
    [allMonths, searchParams]
  );

  return {
    months,
    monthsString,
  };
};
