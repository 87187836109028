import React, { useCallback, memo } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@bwoty-web/styleguide';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';

const Footer = styled.div`
  padding: 4px 20px;

  @media (min-width: ${deviceWidths.tablet}) {
    padding: 4px 0;
  }
`;

export const MonthFooter = memo(({ year, month, onSelect, label, selectedMonths, monthName }) => {
  const mIndex = month + 1;
  const selected = selectedMonths || [];
  const mk = `${year}-${mIndex < 10 ? `0${mIndex}` : mIndex}`;
  const uniqueId = `hh-dp-mf-${mk}`;
  const onChange = useCallback(() => {
    onSelect({ year, month });
  }, [year, month, onSelect]);

  const mLabel = monthName.toLocaleLowerCase();

  return (
    <Footer>
      <Checkbox checked={selected.indexOf(mk) >= 0} uniqueId={uniqueId} onChange={onChange}>
        {label} {mLabel}
      </Checkbox>
    </Footer>
  );
});
