const searchParamsToExcludeFromUrl = ['wvId', 'cookieId', 'siteId'];

const filtersKey = 'filters';
const pageKey = 'p';

const getCurrentUrl = () =>
  `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
const getCurrentSearchParams = () => new URLSearchParams(window.location.search);

export const updateOrAddSearchParam = (key, value) => {
  if (window.history.pushState) {
    const searchParams = getCurrentSearchParams();
    searchParams.set(key, value);
    const newurl = `${getCurrentUrl()}?${searchParams.toString()}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }
};

const analyticParametersToKeep = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
  'dclid',
];

export const deleteSearchParam = (key) => {
  const searchParams = getCurrentSearchParams();
  searchParams.delete(key);

  const newurl = `${getCurrentUrl()}?${searchParams.toString()}`;
  window.history.replaceState({ path: newurl }, '', newurl);
};

export const updateAllSearchParams = (searchParamsObject) => {
  const searchParams = getCurrentSearchParams();

  [...searchParams.keys()].forEach((searchParamKey) => {
    if (
      (searchParamsObject[searchParamKey] === undefined &&
        !analyticParametersToKeep.includes(searchParamKey)) ||
      searchParamKey !== 'filter'
    ) {
      searchParams.delete(searchParamKey);
    }
  });

  Object.keys(searchParamsObject)
    .filter((key) => !searchParamsToExcludeFromUrl.includes(key))
    .filter((key) => searchParamsObject[key])
    .forEach((key) => {
      if (key === pageKey && parseInt(searchParamsObject.p, 10) === 1) {
        searchParams.delete(key);
      } else if (key === filtersKey) {
        const filterObject = searchParamsObject[key];
        const filterArray = Object.keys(filterObject)
          .filter((x) => x) // if empty string
          .filter((x) => filterObject[x]); // if specific filter is set to false

        if (filterArray.length < 1) {
          if (searchParams.has(filtersKey)) {
            searchParams.delete(filtersKey);
          }
          return;
        }

        const filterString = filterArray.filter((x) => filterObject[x]).join(',');
        searchParams.set(filtersKey, filterString);
      } else {
        searchParams.set(key, searchParamsObject[key]);
      }
    });

  // "filter" toggles filter menu in mobile view if "true", if empty it is removed from search params here.
  if (searchParamsObject.filter === undefined) {
    searchParams.delete('filter');
  }

  const hasParams = searchParams.values().next().done === false;
  // const hasParams = [...searchParams.keys()].length > 0;
  const newurl = `${getCurrentUrl()}${hasParams ? `?${searchParams.toString()}` : ''}${
    window.location.hash
  }`;

  window.history.replaceState({ path: newurl }, '', newurl);
};
