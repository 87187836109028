import React from 'react';
import { Message as NewMessage } from '@bwoty-web/styleguide';

const MessageWrapper = (props) => {
  const { type, ...rest } = props;

  if (type === 'error') return <NewMessage messageType="error" {...rest} />;
  if (type === 'information') return <NewMessage messageType="info" {...rest} />;
  if (type === 'info') return <NewMessage messageType="info" {...rest} />;
  if (type === 'warning') return <NewMessage messageType="warning" {...rest} />;
  return <NewMessage messageType="info" {...rest} />;
};

export default MessageWrapper;
