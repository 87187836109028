import { useContext } from 'react';
import { InitialStateContext } from '../context/InitialState';

export const usePreparedData = () => {
  const { prepare } = useContext(InitialStateContext);
  return prepare;
};

export const usePreparedGeographicalData = () => {
  const geoList = usePreparedData().geographical || [];

  const dictionary = {};
  const iterateChildren = (current = [], parents = []) => {
    if (!Array.isArray(current)) {
      return;
    }
    current.forEach((item) => {
      dictionary[item.key] = {
        key: item.key,
        description: item.description,
        resetTree: {},
        children: (item.children || []).map(child => child.key),
        showOnSelected: [],
      };
      parents.forEach((parent) => {
        dictionary[parent].showOnSelected.push(item.key);
        dictionary[item.key].resetTree[parent] = false;
        dictionary[parent].resetTree[item.key] = false;
      });
      iterateChildren(item.children, [...parents, item.key]);
    });
  };
  iterateChildren(geoList);

  return dictionary;
};

export const usePreparedFacets = () => {
  const facets = usePreparedData().facets || [];

  const dictionary = {};
  facets.forEach((item) => {
    dictionary[item.key] = item.description;
  });
  return dictionary;
};
