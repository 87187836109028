import React from 'react';
import { Select } from '@bwoty-web/styleguide';
import { fonts } from '@bwoty-web/styleguide/variables/fonts';
import { colorAliases } from '@bwoty-web/styleguide/variables/colors';
import styled from 'styled-components';
import * as S from './style';

const StyledListBox = styled(Select)`
  background-color: #fff;
  min-width: 120px;
  font-family: ${fonts.bold};
`;

const ValidationWarning = styled.div`
  color: ${colorAliases.validationError};
  padding-top: 10px;
`;

const getChildAgesOptions = (years) =>
  [...Array(18).keys()].map((i) => ({ name: `${i} ${years}`, value: i.toString() }));

const PaxChildrenContainer = ({
  amount,
  updateChildValue,
  childAges,
  texts,
  invalidChildrenAges,
}) => {
  if (!amount || amount === '0') {
    return false;
  }
  const options = getChildAgesOptions(texts.year);

  return (
    <div>
      {[...Array(amount).keys()].map((id) => {
        return (
          <S.PaxPopupChildRow key={id}>
            <div>{`${texts.child} ${id + 1}`}</div>
            <StyledListBox
              invalid={options[childAges[id]] === undefined}
              defaultValue={
                options[childAges[id]] || {
                  name: texts.paxAgeLabel,
                  value: -1,
                }
              }
              value={`${childAges[id]}`}
              items={options}
              onChange={(e) => updateChildValue(e.currentTarget.value, id)}
            />
          </S.PaxPopupChildRow>
        );
      })}
      {invalidChildrenAges && <ValidationWarning>{texts.childrenAgesError}</ValidationWarning>}
    </div>
  );
};

export default PaxChildrenContainer;
