import { useContext } from 'react';
import { InitialStateContext } from '../context/InitialState';

const getTranslationDictionary = (facets) => {
  const dictionary = {};
  (facets || []).forEach((item) => {
    dictionary[item.description] = item;
  });
  return dictionary;
};

const getGeographicalDictionary = (geographical) => {
  const geoList = geographical || [];

  const dictionary = {};
  const iterateChildren = (current = [], parents = []) => {
    if (!Array.isArray(current)) {
      return;
    }
    current.forEach((item) => {
      dictionary[item.key] = {
        key: item.key,
        description: item.description,
        resetTree: {},
        children: (item.children || []).map((child) => child.key),
        showOnSelected: [],
      };
      parents.forEach((parent) => {
        dictionary[parent].showOnSelected.push(item.key);
        dictionary[item.key].resetTree[parent] = false;
        dictionary[parent].resetTree[item.key] = false;
      });
      iterateChildren(item.children, [...parents, item.key]);
    });
  };
  iterateChildren(geoList);

  return dictionary;
};

const getGeographicalTopItems = (geographical) => {
  return Array.isArray(geographical) ? geographical.map((item) => item.key) : [];
};

const getTravelType = (alias) => {
  const travelType = {
    hotelfinder: 'CharterPackage',
    hotelfindercity: 'IndependentPackage',
    hotelfindercityandsun: 'CharterPackage',
    hotelfinderarea: 'CharterPackage',
    hotelfindercountry: 'CharterPackage',
    hotelfinderworld: 'CharterPackage',
    hotelfinderregion: 'CharterPackage',
  };
  return travelType[alias];
};

export const useInitialState = () => {
  const {
    text,
    umbracoTexts,
    prepare,
    featureToggles,
    location,
    defaults,
    analytics,
    hotels,
    predefined,
    didServerRenderHotels,
    imageUrl,
    clientOrigoUrl,
  } = useContext(InitialStateContext);

  const translationDictionary = getTranslationDictionary(prepare?.facets);
  const geographicalDictionary = getGeographicalDictionary(prepare?.geographical);
  const geographicalTopItems = getGeographicalTopItems(prepare?.geographical);
  const travelType = getTravelType(umbracoTexts?.__meta?.alias);

  return {
    text,
    umbracoTexts,
    defaults,
    allDepartures: prepare?.departures,
    districts: prepare?.districts,
    monthNames: prepare?.monthNames,
    meta: prepare?.meta,
    translationDictionary,
    geographicalDictionary,
    geographicalTopItems,
    travelType,
    featureToggles,
    location,
    analytics,
    hotels,
    didServerRenderHotels,
    predefined,
    imageUrl,
    clientOrigoUrl,
  };
};
