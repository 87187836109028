import React from 'react';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import styled from 'styled-components';
import { cssFadeIn } from '../../utils/cssFadeIn';

const PriceDetailsStyledTable = styled.div`
  display: table;
  margin-top: 8px;
  width: 100%;
  ${cssFadeIn}

  @media (min-width: ${deviceWidths.tablet}) {
    width: auto;
  }
`;

const PriceDetailsInfoRow = styled.div`
  display: table-row;
`;

const PriceDetailsInfoTableCell = styled.div`
  display: table-cell;
  padding-bottom: 7px;

  @media (max-width: ${deviceWidths.tabletMax}) {
    width: 50%;
  }

  &:first-of-type {
    @media (min-width: ${deviceWidths.tablet}) {
      width: 190px;
    }
  }
`;

const PriceDetailsInfoTable = ({ priceDetailsTexts, pax, details, hotel }) => {
  return (
    <PriceDetailsStyledTable>
      <PriceDetailsInfoRow>
        <PriceDetailsInfoTableCell>{priceDetailsTexts?.pax}</PriceDetailsInfoTableCell>
        <PriceDetailsInfoTableCell>{pax}</PriceDetailsInfoTableCell>
      </PriceDetailsInfoRow>
      <PriceDetailsInfoRow>
        <PriceDetailsInfoTableCell>{priceDetailsTexts?.rooms}</PriceDetailsInfoTableCell>
        <PriceDetailsInfoTableCell>
          {(details?.rooms || [])
            .map((room) => {
              if (room.amount > 1) {
                return `${room.name} (${room.amount})`;
              }
              return room.name;
            })
            .join(', ')}
        </PriceDetailsInfoTableCell>
      </PriceDetailsInfoRow>
      <PriceDetailsInfoRow>
        <PriceDetailsInfoTableCell>{priceDetailsTexts?.meal}</PriceDetailsInfoTableCell>
        <PriceDetailsInfoTableCell>
          {hotel?.accommodations?.[0]?.rooms?.[0]?.boardCodeDescription}
        </PriceDetailsInfoTableCell>
      </PriceDetailsInfoRow>
      <PriceDetailsInfoRow>
        <PriceDetailsInfoTableCell>{priceDetailsTexts?.departureAirport}</PriceDetailsInfoTableCell>
        <PriceDetailsInfoTableCell>{details?.departureName}</PriceDetailsInfoTableCell>
      </PriceDetailsInfoRow>
      <PriceDetailsInfoRow>
        <PriceDetailsInfoTableCell>{priceDetailsTexts?.departureTime}</PriceDetailsInfoTableCell>
        <PriceDetailsInfoTableCell>
          {details?.departureTime?.long}, {details?.departureTime?.time}
        </PriceDetailsInfoTableCell>
      </PriceDetailsInfoRow>
      <PriceDetailsInfoRow>
        <PriceDetailsInfoTableCell>{priceDetailsTexts?.duration}</PriceDetailsInfoTableCell>
        <PriceDetailsInfoTableCell>
          {details?.duration} {priceDetailsTexts?.days}
        </PriceDetailsInfoTableCell>
      </PriceDetailsInfoRow>
    </PriceDetailsStyledTable>
  );
};

export default PriceDetailsInfoTable;
