import React from 'react';
import Checkbox from './Checkbox';
import ChildCheckboxGroup from './ChildCheckboxGroup';
import transformKey from '../../utils/transformKey';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

export const FilterCheckGroupDumb = (props) => {
  const { prefix, data, counts, translation, searchParams, refetchHotels } = props;
  if (!data?.name || !data?.values) {
    return null;
  }

  const longKey = data?.key;
  const shortKey = translation?.[longKey]?.key;
  const count = counts?.[shortKey] || 0;
  const resetChildren = {};
  Object.keys(data.values).forEach((key) => {
    const childShortKey = translation?.[key]?.key;
    resetChildren[childShortKey] = false;
  });

  const { filters = {} } = searchParams;

  const handleParentChange = (e) => {
    e.persist();

    refetchHotels({
      newSearchParams: {
        filters: {
          ...resetChildren,
          [shortKey]: !!count && e.target.checked,
        },
      },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        params: {
          travelFilterCategory: transformKey(prefix.split('.')[0]),
          travelFilterValue: transformKey(longKey),
        },
        shouldSendEvent: !!count && e.target.checked,
      },
    });
  };

  const handleChildChange = (e, childShortKey, childCount, key) => {
    e.persist();

    refetchHotels({
      newSearchParams: {
        filters: {
          [childShortKey]: !!childCount && e.target.checked,
          [shortKey]: false,
        },
      },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        params: {
          travelFilterCategory: transformKey(prefix.split('.')[0]),
          travelFilterValue: transformKey(key),
        },
        shouldSendEvent: !!childCount && e.target.checked,
      },
    });
  };

  return (
    <div>
      <Checkbox
        checked={Boolean(filters[shortKey])}
        onChange={handleParentChange}
        uniqueId={`${prefix}${shortKey}`}
        disabled={!count}
      >
        {data?.name} ({count})
      </Checkbox>
      <ChildCheckboxGroup>
        {Object.keys(data.values).map((key) => {
          const childShortKey = translation?.[key].key;
          const childCount = counts?.[childShortKey] || 0;
          return (
            <Checkbox
              key={childShortKey}
              checked={Boolean(filters[childShortKey])}
              onChange={(e) => handleChildChange(e, childShortKey, childCount, key)}
              uniqueId={`${prefix}${childShortKey}`}
              disabled={!childCount}
            >
              {data.values?.[key]} ({childCount})
            </Checkbox>
          );
        })}
      </ChildCheckboxGroup>
    </div>
  );
};

FilterCheckGroupDumb.defaultProps = defaultProps;

export default FilterCheckGroupDumb;
