/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Link } from '@bwoty-web/styleguide';
import Message from './Message';
import { useHotelsContext } from '../../context/Hotels';

const StyledMessage = styled(Message)`
  margin-bottom: 12px;
`;

const MessageContainer = () => {
  const hotelsResult = useHotelsContext();

  const { hotelMessages, refetchHotels } = hotelsResult;

  const hotelErrorMessage =
    (hotelsResult.error || hotelsResult.hotelIds.length) &&
    hotelsResult?.hotelMessages?.filterError;

  const hotelMessage = hotelMessages?.[0];

  if (!hotelErrorMessage && !hotelMessage) {
    return null;
  }

  if (hotelMessage) {
    return (
      <StyledMessage header={hotelMessage.header} isInitiallyExpanded type={hotelMessage.type}>
        <div dangerouslySetInnerHTML={{ __html: hotelMessage.message }} />
      </StyledMessage>
    );
  }
  const messageSplit = (hotelErrorMessage?.message || '').split(/{|}/);
  const type = hotelErrorMessage?.type || '';

  if (messageSplit.length !== 3) {
    return null;
  }

  return (
    <div>
      <StyledMessage
        header={hotelErrorMessage.header}
        isInitiallyExpanded
        type={type.toLowerCase()}
      >
        {messageSplit[0]}
        <Link
          onClick={() =>
            refetchHotels({
              newSearchParams: {
                filters: '',
                guestRatingFrom: '',
                guestRatingTo: '',
                distanceMetroTo: '',
                priceTo: '',
                distanceCenterTo: '',
                distanceBeachTo: '',
                maxTransfer: '',
                weeks: '',
                departure: '',
                duration: '',
                pax: '',
              },
            })
          }
        >
          {messageSplit[1]}
        </Link>
        {messageSplit[2]}
      </StyledMessage>
    </div>
  );
};

export default MessageContainer;
