import React from 'react';
import * as S from './style';

const DurationOption = ({ option, selected, onUpdate }) => {
  if (!option || !option.value || !option.name) {
    return false;
  }
  return (
    <S.DurationPopupOption onClick={(e) => onUpdate(option.value, e)} styleSelected={selected}>
      {option.name}
    </S.DurationPopupOption>
  );
};

export default DurationOption;
