import React, { createContext, useContext } from 'react';
import { useHotels } from '../hooks/useHotels';

const HotelsContext = createContext();

const HotelsProvider = ({ children }) => {
  const hotelsResult = useHotels();
  return <HotelsContext.Provider value={hotelsResult}>{children}</HotelsContext.Provider>;
};

export const useHotelsContext = () => useContext(HotelsContext);
export default HotelsProvider;
