import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Button } from '@bwoty-web/styleguide';
import { DatePicker } from '../datepicker/DatePicker';
import { IntervalSelection } from '../datepicker/IntervalSelection';
import * as S from './style';
import { SelectedValue } from './SelectedValue';
import Overlay from './SearchOverlay';
import { DatePickerSelectedValue } from '../datepicker/DatePickerSelectedValue';
import { NoSSR } from '../NoSSR';
import { useInitialState } from '../../hooks/useInitialState';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

dayjs.extend(customParseFormat);

const submit = (close) => (e) => {
  e.stopPropagation();
  close(e);
};

const getSelectedInterval = (selectedDate, interval) => {
  if (!interval || !selectedDate) {
    return null;
  }

  const date = new Date(selectedDate);
  const before = new Date(selectedDate);
  const after = new Date(selectedDate);

  before.setDate(date.getDate() - parseInt(interval, 10));
  after.setDate(date.getDate() + parseInt(interval, 10));

  const beforeDiff = dayjs(before).diff(dayjs(), 'day');

  // cannot select past dates
  if (beforeDiff < 0) {
    before.setDate(before.getDate() + beforeDiff * -1);
  }

  return { from: before.toISOString(), to: after.toISOString() };
};

const analyticsData = { type: ANALYTICS_DATA_TYPES.SEARCH };

const monthClick = (month, refetchHotels, selectedMonths) => {
  const index = selectedMonths.indexOf(month);
  if (index !== -1) {
    selectedMonths.splice(index, 1);
  } else {
    selectedMonths.push(month);
  }

  refetchHotels({
    newSearchParams: {
      QueryDepInterval: null,
      QueryDepDate: null,
      QueryDepMonths: selectedMonths.join(','),
      weeks: '',
    },
    analyticsData,
  });
};

const startDate = dayjs().format('YYYY-MM-DD');

const DepartureDateContainer = ({ onClose, isOpen, searchParams, refetchHotels }) => {
  const {
    QueryDepDate: selectedDate,
    QueryDepMonths: queryDepMonths,
    QueryDepInterval,
  } = searchParams;

  const { monthNames: months, umbracoTexts } = useInitialState();
  const { labels: texts } = umbracoTexts;

  const queryDepInterval = parseInt(QueryDepInterval || 0, 10);

  const onIntervalClick = (interval) => {
    const current = queryDepInterval;
    const next = parseInt(interval, 10);

    if (next === current) {
      refetchHotels({
        newSearchParams: { QueryDepInterval: null, months: null, weeks: '' },
        analyticsData,
      });
    } else {
      refetchHotels({
        newSearchParams: { QueryDepInterval: next, QueryDepMonths: null, weeks: '' },
        analyticsData,
      });
    }
  };

  const selectedMonths = queryDepMonths ? queryDepMonths.split(',') : [];
  const selectedMonthsStr = selectedMonths.map((mm) => `${mm.slice(0, 4)}-${mm.slice(4)}`);
  const selectedInterval = getSelectedInterval(selectedDate, queryDepInterval);

  const onDayClick = (dateStr) => {
    refetchHotels({
      newSearchParams: { QueryDepDate: dateStr, QueryDepMonths: null, weeks: '' },
      analyticsData,
    });
  };

  const onMonthClick = ({ month, year }) => {
    // month start from 0
    const m = month + 1;
    const key = `${year}${m < 10 ? `0${m}` : m}`;
    monthClick(key, refetchHotels, selectedMonths);
  };

  const fullMonthSelected = selectedMonths.length > 0;

  return (
    <div>
      <SelectedValue label={texts.departureDate} isOpen={isOpen}>
        <DatePickerSelectedValue
          emptyLabel={texts.chooseDate}
          selectedDate={selectedDate}
          selectedMonths={selectedMonths}
          selectedInterval={selectedInterval}
          monthNames={months}
        />
      </SelectedValue>
      <S.SearchOptionDropDownWrapper styleOpen={isOpen}>
        <S.SearchOptionDropDown close={onClose} closed={!isOpen} header={texts.chooseDate}>
          <S.SearchOptionDropDownInner>
            <S.SearchOptionDropDownBody>
              <NoSSR>
                <DatePicker
                  startDate={startDate}
                  isOpen={isOpen}
                  onDayClick={onDayClick}
                  onMonthClick={onMonthClick}
                  selectedDate={selectedDate}
                  selectedMonths={selectedMonthsStr}
                  selectedInterval={selectedInterval}
                  texts={texts}
                />
              </NoSSR>
            </S.SearchOptionDropDownBody>
            <S.SearchOptionDropDownFooter>
              {fullMonthSelected ? null : (
                <IntervalSelection
                  selected={queryDepInterval}
                  onSelect={onIntervalClick}
                  dayLabel={texts?.day}
                  daysLabel={texts?.days}
                />
              )}
              <S.SearchOptionDropDownButtonWrap>
                <Button
                  theme="primary"
                  className="tcne-hh-search-filter-update"
                  onClick={submit(onClose)}
                >
                  {texts.paxConfirm}
                </Button>
              </S.SearchOptionDropDownButtonWrap>
            </S.SearchOptionDropDownFooter>
          </S.SearchOptionDropDownInner>
        </S.SearchOptionDropDown>
      </S.SearchOptionDropDownWrapper>
      {isOpen && <Overlay onClick={onClose} />}
    </div>
  );
};

export default DepartureDateContainer;
