import { useMemo } from 'react';

const DURATION_DICTIONARY = [8, 15, 22, 29, 33, 5];

export const getDurationFromSearchParam = (searchParams) => {
  const dur = parseInt(searchParams.QueryDur, 10);
  if (DURATION_DICTIONARY.indexOf(dur) > -1) {
    return dur;
  }
  return DURATION_DICTIONARY[0];
};

export const useDuration = (searchParams) => {
  const duration = useMemo(() => getDurationFromSearchParam(searchParams), [searchParams]);
  return { duration };
};
