import { InMemoryCache, ApolloClient, HttpLink } from '@apollo/client';

import customFetch from '../../server/util/customFetch';

import normalize from '../utils/normalize';
import { hotelQuery } from './hotelsQuery';
import { hotelCountQuery } from './hotelCountQuery';
import { umbracoQuery, umbracoQueryForOffers } from './umbracoQuery';
import { detailsQuery } from './detailsQuery';
import { districtQuery } from './districtQuery';
import { initialQuery } from './initialQuery';

export const getClient = (uri, marketunit, headers = {}) => {
  const client = new ApolloClient({
    link: new HttpLink({
      fetch: customFetch,
      uri,
      headers: {
        marketunit,
        'x-autotest-version': headers['x-autotest-version'] || '',
        'x-caller-app': 'HotelFinder',
        'x-origo-context': headers['x-origo-context'] || '',
      },
    }),
    connectToDevTools: true,

    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  return client;
};

export async function getInitialData(config) {
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  try {
    const result = await client.query({
      query: initialQuery,
      variables: {
        key: config.key,
        paxAges: config.paxAges,
        isInvalidPax: !!config.isInvalidPax,
      },
      context: {
        headers: config.headers,
        fetchOptions: {
          timeout: 15000,
        },
      },
    });

    const hotels = normalize(result?.data?.hotelFinderHotels?.hotels || [], (hotel) => hotel.wvId);
    hotels.count = result?.data?.hotelFinderHotels?.count;
    hotels.totalCount = result?.data?.hotelFinderHotels?.totalCount;
    hotels.facetsCounts = result?.data?.hotelFinderHotels?.facets || [];
    hotels.tripPrice = result?.data?.hotelFinderHotels?.tripPrice;
    hotels.messages = result?.data?.hotelFinderHotels?.messages;

    return {
      hotels,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in fetch', error);
  }
  return {};
}

export async function getInitialHotelCount(config) {
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  try {
    const result = await client.query({
      query: hotelCountQuery,
      variables: {
        key: config.key,
      },
      context: {
        headers: config.headers,
        fetchOptions: {
          timeout: 15000,
        },
      },
    });
    return result?.data?.hotelFinderHotels?.count;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in fetch', error);
  }
  return {};
}

export async function getUmbracoTextAndPrepare(config) {
  // logger.info('getUmbracoTextAndPrepare', config);
  if (config.mucd === 'ds') {
    /*
     * Globetrotter is missing translations in Umbraco
     * Use Ving SE translations as they should be identical
     */
    // eslint-disable-next-line no-param-reassign
    config.mucd = 'vs';
  }
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  try {
    const result = await client.query({
      query: umbracoQuery,
      variables: {
        geoId: config.geoId,
        destCaId: config.destCaId,
        haveDestId: !!config.destCaId,
      },
      context: {
        headers: config.headers,
        fetchOptions: {
          timeout: 15000,
        },
      },
    });

    const prepare = {
      facets: result?.data?.hotelFinderPreparev2?.facetMappings || [],
      paxOptions: result?.data?.hotelFinderPreparev2?.paxOptions || [],
      departures: result?.data?.hotelFinderPreparev2?.departures || [],
      geographical: result?.data?.hotelFinderPreparev2?.geographical || [],
      departureYearWeek: result?.data?.hotelFinderPreparev2?.departureYearWeek,
      departureYearMonth: result?.data?.hotelFinderPreparev2?.departureYearMonth,
      allMonths: result?.data?.hotelFinderPreparev2?.allMonths,
      districts: result?.data?.hotelFinderPreparev2?.districts,
      weekDaysShort: result?.data?.hotelFinderPreparev2?.weekDaysShort,
      monthNames: result?.data?.hotelFinderPreparev2?.monthNames,
      meta: result?.data?.hotelFinderPreparev2?.meta,
      mayHaveCharter: result?.data?.hotelFinderPreparev2?.mayHaveCharter,
    };
    return {
      umbracoTexts: result?.data?.hotelFinderTexts,
      prepare,
      text: result?.data?.text,
      destWvId: result?.data?.team1GeoCaIdToWvId?.id,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in fetch', error);
  }
  return {};
}

export async function getUmbracoTextAndPrepareForOffers(config) {
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  try {
    const result = await client.query({
      query: umbracoQueryForOffers,
      variables: {
        geoId: config.geoId,
      },
      context: {
        headers: config.headers,
        fetchOptions: {
          timeout: 15000,
        },
      },
    });

    const prepare = {
      departures: result?.data?.hotelFinderPreparev2?.departures || [],
      allMonths: result?.data?.hotelFinderPreparev2?.allMonths,
    };

    return {
      prepare,
      text: result?.data?.text?.webuiHotelHit,
      hotelFinderTexts: result?.data?.hotelFinderTexts,
      labels: result?.data?.hotelFinderTexts?.labels,
      umbracoLinks: result?.data?.hotelFinderTexts?.links,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in fetch', error);
  }
  return {};
}

export async function getHotels(key, config, timeout = 20) {
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  try {
    const result = await client.query({
      query: hotelQuery,
      variables: {
        key,
        paxAges: config.paxAges,
        isInvalidPax: !!config.isInvalidPax,
      },
      context: {
        headers: config.headers,
        fetchOptions: {
          timeout: 15000,
        },
      },
    });

    const hotels = normalize(result?.data?.hotelFinderHotels?.hotels || [], (hotel) => hotel.wvId);
    hotels.count = result?.data?.hotelFinderHotels?.count;
    hotels.totalCount = result?.data?.hotelFinderHotels?.totalCount;
    hotels.facetsCounts = result?.data?.hotelFinderHotels?.facets || [];
    hotels.tripPrice = result?.data?.hotelFinderHotels?.tripPrice;
    hotels.messages = result?.data?.hotelFinderHotels?.messages;

    return hotels;
  } catch (error) {
    /*
     * Sometimes this request fails due to an error in the loadbalancer
     * Therefore we need to retry this reuqest recursively until it works
     * The requst timeout is increased incrementaly for each turn by a factor of 1.5
     */

    const errorReason = error.message?.split('reason:')[1];
    if (errorReason && errorReason.trim() === 'incorrect data check') {
      setTimeout(() => {
        return getHotels(key, config, timeout * 1.5);
      }, timeout);
    }

    // eslint-disable-next-line no-console
    console.error('error in fetch', error);
  }
  return {};
}

export async function getDetails(key, config) {
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  const result = await client.query({
    query: detailsQuery,
    variables: {
      key,
      fetchTexts: !!config.fetchTexts,
      useNewPriceDetails: !!config.useNewPriceDetails, // TODO: Remove this toggle.. old solution should be removed
    },
    context: {
      headers: config.headers,
      fetchOptions: {
        timeout: 15000,
      },
    },
  });

  return {
    priceDetails:
      result?.data?.team1PriceDetailsFromFilter || result?.data?.hotelFinderPriceDetails,
    priceSpec: result?.data?.attributeCollection?.['price-spec'],
  };
}

export async function getDistrict(id, config) {
  const client = getClient(config.origoUrl, config.mucd, config.headers);
  try {
    const result = await client.query({
      query: districtQuery,
      variables: {
        id,
      },
      context: {
        headers: config.headers,
        fetchOptions: {
          timeout: 15000,
        },
      },
    });
    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in fetch', error);
  }
  return {};
}
