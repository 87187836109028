import { useMemo } from 'react';

export const getOrderBy = (searchParams) => {
  return searchParams.orderBy || 'Y';
};

export const useOrderBy = (searchParams) => {
  const orderBy = useMemo(() => getOrderBy(searchParams), [searchParams]);
  return { orderBy };
};
