import React from 'react';
import FilterGeographicalEntry from './FilterGeographicalEntry';
import { useInitialState } from '../../hooks/useInitialState';

const defaultProps = {
  prefix: '',
};

const FilterGeographical = (props) => {
  const { prefix, counts, searchParams, refetchHotels } = props;

  const { geographicalTopItems: geographical } = useInitialState();

  return (
    <div>
      {geographical.map((geoId) => (
        <FilterGeographicalEntry
          geoId={geoId}
          key={geoId}
          prefix={prefix}
          counts={counts}
          searchParams={searchParams}
          refetchHotels={refetchHotels}
        />
      ))}
    </div>
  );
};

FilterGeographical.defaultProps = defaultProps;

export default FilterGeographical;
