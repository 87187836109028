import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSwipeable, DOWN as DirectionDown } from 'react-swipeable';
import styled, { css } from 'styled-components';

const SwipeDownContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;

  ${(props) =>
    props.transition &&
    css`
      transition: bottom 0.5s ease;
    `}
`;

const SwipeDownInner = styled.div`
  position: relative;
`;

function addEventListener(node, event, handler) {
  node.addEventListener(event, handler);

  return {
    dispose: () => {
      node.removeEventListener(event, handler);
    },
  };
}

function useClickOutSide(ref, callback) {
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      callback(event);
    };

    const touchListener = addEventListener(document, 'touchstart', handler);

    return () => {
      touchListener.dispose();
    };
  }, [callback, ref]);
}

const getInnerHeight = (ref) => {
  if (ref.current) {
    // actual height of swipeable component
    return ref.current.offsetHeight;
  }
  return 136;
};

export const SwipeDownClose = (props) => {
  const { id, className, children, onClose, onClickOutside } = props;

  const [bottomPosition, setBottomPos] = useState(0);
  const [transition, setTransition] = useState(true);
  const innerRef = useRef();
  const prevIdRef = useRef(id);

  const updateBottomPos = useCallback(
    (delta, animate = false) => {
      setTransition(() => animate);
      setBottomPos(() => delta);
    },
    [setBottomPos, setTransition]
  );

  const slideOutAndClose = useCallback(() => {
    updateBottomPos(getInnerHeight(innerRef) * -2, true);
    // don't close, we showing another inner component.
    setTimeout(() => {
      if (prevIdRef.current !== id) {
        onClose();
      }
    }, 500);
  }, [updateBottomPos, id, onClose]);

  const handleSwiping = useCallback(
    (event) => {
      if (event.dir === DirectionDown) {
        updateBottomPos(event.deltaY);
      }
    },
    [updateBottomPos]
  );

  const handleSwipedDown = useCallback(
    (event) => {
      const threshold = -0.7 * getInnerHeight(innerRef);

      if (event.deltaY < threshold) {
        slideOutAndClose();
      } else {
        // reset to initial position
        updateBottomPos(0, true);
      }
    },
    [slideOutAndClose, updateBottomPos]
  );

  const handleClickOutside = useCallback((event) => {
    const prevented = onClickOutside(event);
    if (!prevented) {
      slideOutAndClose();
    }
  }, []);

  useEffect(() => {
    prevIdRef.current = id;
  }, [id]);

  // reset position if id changes.
  useEffect(() => {
    if (id !== prevIdRef.current) {
      updateBottomPos(0, false);
    }
  }, [id, updateBottomPos]);

  const style = { bottom: bottomPosition };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwipedDown: handleSwipedDown,
    trackTouch: true,
    trackMouse: false,
    preventScrollOnSwipe: true,
  });

  useClickOutSide(innerRef, handleClickOutside);

  return (
    <SwipeDownContainer transition={transition} style={style} className={className} {...handlers}>
      <SwipeDownInner key={id} ref={innerRef}>
        {children}
      </SwipeDownInner>
    </SwipeDownContainer>
  );
};

const noop = () => {};

SwipeDownClose.defaultProps = {
  className: undefined,
  onClosing: noop,
  onClickOutside: noop,
};

export default SwipeDownClose;
