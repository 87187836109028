import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 12px;
  margin-top: 4px;
`;

const Heading = ({ children }) => <StyledSpan>{children}</StyledSpan>;

export default Heading;
