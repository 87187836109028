import React from 'react';
import { colors } from '@bwoty-web/styleguide/variables/colors';
import { LoadBlock } from '@bwoty-web/styleguide';
import styled, { css } from 'styled-components';
import { cssFadeIn } from '../../utils/cssFadeIn';
import { formatPriceCurrency } from '../../utils/formatPrice';

const PriceDetailsTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  ${cssFadeIn}
`;

const PriceDetailsTable = styled.div`
  display: table;
  width: 100%;
  margin-top: 12px;
  ${cssFadeIn}
`;

const TableLoader = styled(LoadBlock)`
  height: 36px;
  width: 100%;
  margin-top: 12px;

  &:first-of-type {
    margin-top: 12px;
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const PriceDetailsTableRow = styled.div`
  display: table-row;
`;

const PriceDetailsTableCell = styled.div`
  border-bottom: ${colors.lightGray} solid 1px;
  display: table-cell;
  padding: 12px 0;
  ${(props) =>
    props.description &&
    css`
      text-align: left;
    `}
  ${(props) =>
    props.amount &&
    css`
      width: 30px;
      text-align: left;
    `}
  ${(props) =>
    (props.price || props.total) &&
    css`
      width: 75px;
      text-align: right;
    `}
  ${(props) =>
    props.equal &&
    css`
      width: 30px;
      text-align: right;
    `}
`;

const PriceDetailsComponents = (props) => {
  const { details, isLoading, priceDetailsTexts } = props;
  if (!details && !isLoading) {
    return null;
  }
  const components = details?.components || [];
  const totalPrice = details?.totalPrice;

  if (isLoading)
    return (
      <>
        <TableLoader />
        <TableLoader />
        <TableLoader />
      </>
    );

  return (
    <>
      <PriceDetailsTable>
        {components
          .filter((component) => component.totalPrice)
          .map((component, i) => (
            <PriceDetailsTableRow key={i}>
              <PriceDetailsTableCell description>{component.description}:</PriceDetailsTableCell>
              <PriceDetailsTableCell amount>{component.amount} x</PriceDetailsTableCell>
              <PriceDetailsTableCell price>
                {formatPriceCurrency(component.price, component.currencyCode)}
              </PriceDetailsTableCell>
              <PriceDetailsTableCell equal>=</PriceDetailsTableCell>
              <PriceDetailsTableCell total>
                {formatPriceCurrency(component.totalPrice, component.currencyCode)}
              </PriceDetailsTableCell>
            </PriceDetailsTableRow>
          ))}
      </PriceDetailsTable>
      {!!totalPrice && (
        <PriceDetailsTotal>
          <h3>{priceDetailsTexts?.totalPrice}</h3>
          <h3>{formatPriceCurrency(totalPrice, details?.currencyCode || 'SEK')}</h3>
        </PriceDetailsTotal>
      )}
    </>
  );
};

export default PriceDetailsComponents;
