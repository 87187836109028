import React from 'react';
import { Checkbox } from '@bwoty-web/styleguide';
import * as S from './style';

const DepartureRow = ({ departure, onSelect, selectedDepartures }) => {
  if (!departure || !departure.key) {
    return null;
  }

  const isSelected = !!selectedDepartures.find((dd) => dd === departure.key);

  return (
    <S.DepartureRow styleSelected={isSelected}>
      <Checkbox
        key={departure.key}
        checked={isSelected}
        onChange={() => onSelect(departure.key)}
        uniqueId={departure.key}
      >
        {departure.value}
      </Checkbox>
    </S.DepartureRow>
  );
};

export default DepartureRow;
