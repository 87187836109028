import React from 'react';
import HotelContainer from '../HotelContainer';
import * as S from './style';

const SelectedHotel = ({
  id,
  hotel,
  onClosing,
  onClose,
  location,
}) => {
  const handleClickOutside = (event) => {
    if (event.target.classList.contains('tcne-hf-marker')) {
      return true;
    }
    return false;
  };

  const hotelCard = (
    <HotelContainer
      highlight
      id={hotel.wvId}
      onClose={onClose}
      location={location}
      hotelObject={hotel}
    />
  );

  return (
    <S.SwipeCard
      onClose={onClose}
      onClosing={onClosing}
      onClickOutside={handleClickOutside}
      id={id}
      key={id}
    >
      {hotelCard}
    </S.SwipeCard>
  );
};

export default SelectedHotel;
