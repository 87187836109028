export function parsePriceSpecificationUrl(url) {
  const [
    ages,
    classCode,
    departureCode,
    dateStr,
    destinationCode,
    duration,
    mu,
    serialNumnber,
    hotelCode,
    roomType,
    roomCount,
  ] = (url || '').split('/'); // 42,42/E/ARN/2018-04-14/PMI/8/VS/61/PRIN/A12FSE/1

  return {
    ages,
    classCode,
    departureCode,
    dateStr,
    destinationCode,
    duration,
    mu,
    serialNumnber,
    hotelCode,
    roomType,
    roomCount,
  };
}
