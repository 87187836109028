import React from 'react';
import * as S from './style';

const HotelInfoCard = ({
  hidden,
  hotelName,
  thumbnail = {},
  price,
  discount,
}) => {
  return (
    <S.StyledInfoCard hidden={hidden} header={hotelName} thumbnail={thumbnail}>
      {price ? (
        <S.StyledInfoCardPrice $isDiscount={discount}>{price}</S.StyledInfoCardPrice>
      ) : null}
    </S.StyledInfoCard>
  );
};

export default HotelInfoCard;
