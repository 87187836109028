import React from 'react';
import Checkbox from './Checkbox';
import CheckboxWithInfo from './CheckboxWithInfo';
import FilterCollection from './FilterCollection';
import FilterRating from './FilterRating';
import FilterGuestRating from './FilterGuestRating';
import FilterCheckGroup from './FilterCheckGroup';
import FilterPrice from './FilterPrice';
import FilterCenter from './FilterCenter';
import FilterBeach from './FilterBeach';
import FilterDistricts from './FilterDistricts';
import FilterMetro from './FilterMetro';
import FilterGeographical from './FilterGeographical';
import { useInitialState } from '../../hooks/useInitialState';
import transformKey from '../../utils/transformKey';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

export const FilterInputDumb = (props) => {
  const { prefix, facetKey, data, facetCounts, searchParams, tripPrice, refetchHotels } = props;

  const { translationDictionary: translation, umbracoTexts } = useInitialState();
  const { labels: umbracoLabels } = umbracoTexts;

  const { filters = {} } = searchParams;

  const key = data?.key || facetKey;
  const translationKey = translation?.[key]?.key;
  const count = facetCounts?.[translationKey] || 0;

  const handleChange = (e) => {
    refetchHotels({
      newSearchParams: {
        filters: {
          [translationKey]: !!count && e.target.checked,
        },
      },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        shouldSendEvent: !!count && e.target.checked,
        params: {
          travelFilterCategory: transformKey(prefix.split('.')[0]),
          travelFilterValue: transformKey(key),
        },
      },
    });
  };

  if (typeof data === 'string' && translation?.[key]) {
    return (
      <Checkbox
        checked={Boolean(filters[translationKey])}
        onChange={handleChange}
        uniqueId={`${prefix}${translationKey}`}
        disabled={!count && !filters[translationKey]}
      >
        {data} ({count})
      </Checkbox>
    );
  }

  if (typeof data === 'object') {
    switch (data?.facetType) {
      case 'checkbox':
        return (
          <CheckboxWithInfo
            checked={Boolean(filters[translationKey])}
            onChange={handleChange}
            uniqueId={`${prefix}${translationKey}`}
            disabled={!count && !filters[translationKey]}
            description={data?.description}
          >
            {data?.name} ({count})
          </CheckboxWithInfo>
        );
      case 'rating':
        return (
          <FilterRating
            searchParams={searchParams}
            counts={facetCounts}
            heading={data?.name}
            prefix={`${prefix}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      case 'districts':
        return (
          <FilterDistricts
            searchParams={searchParams}
            counts={facetCounts}
            heading={data?.name}
            prefix={`${prefix}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      case 'geographical':
        return (
          <FilterGeographical
            searchParams={searchParams}
            counts={facetCounts}
            prefix={`${prefix}${prefix ? '.' : ''}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      case 'collection':
        return (
          <FilterCollection
            facetCounts={facetCounts}
            searchParams={searchParams}
            prefix={`${prefix}${prefix ? '.' : ''}${facetKey}`}
            data={data}
            tripPrice={tripPrice}
            refetchHotels={refetchHotels}
          />
        );
      case 'checkGroup':
        return (
          <FilterCheckGroup
            searchParams={searchParams}
            counts={facetCounts}
            prefix={`${prefix}${prefix ? '.' : ''}${facetKey}`}
            data={data}
            translation={translation}
            refetchHotels={refetchHotels}
          />
        );
      case 'guestRating':
        return (
          <FilterGuestRating
            searchParams={searchParams}
            heading={data?.name}
            label={data?.label}
            prefix={`${prefix}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      case 'price':
        return (
          <FilterPrice
            searchParams={searchParams}
            heading={data?.name}
            label={data?.label}
            noPricesText={umbracoLabels?.noPricesText}
            prefix={`${prefix}${facetKey}`}
            tripPrice={tripPrice}
            refetchHotels={refetchHotels}
          />
        );
      case 'distanceToCenterSlider':
        return (
          <FilterCenter
            searchParams={searchParams}
            heading={data?.name}
            label={data?.label}
            prefix={`${prefix}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      case 'distanceToBeachSlider':
        return (
          <FilterBeach
            searchParams={searchParams}
            heading={data?.name}
            label={data?.label}
            prefix={`${prefix}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      case 'metroSlider':
        return (
          <FilterMetro
            searchParams={searchParams}
            heading={data?.name}
            label={data?.label}
            prefix={`${prefix}${facetKey}`}
            refetchHotels={refetchHotels}
          />
        );
      default:
        return null;
    }
  }
  return null;
};

FilterInputDumb.defaultProps = defaultProps;

export default FilterInputDumb;
