export const getActiveFiltersWithTextAndKey = (
  query,
  shortKeyTranslation,
  umbracoFiltersText,
  geographicalDictionary
) => {
  const activeFiltersWithTextAndKey = [];
  const filters = query.filters
    ? Object.keys(query.filters).filter((key) => query.filters[key])
    : [];
  const hasGuestRating = query.guestRatingFrom || query.guestRatingTo;

  // TODO: Förklara varför hårdkoda värden och grejer
  if (hasGuestRating) {
    activeFiltersWithTextAndKey.push({
      ...umbracoFiltersText.guestRating,
      keys: ['guestRatingFrom', 'guestRatingTo'],
      values: [query.guestRatingFrom || '30', query.guestRatingTo || '50'],
      longKey: 'guestRating',
    });
  }

  if (query.priceTo) {
    activeFiltersWithTextAndKey.push({
      ...umbracoFiltersText.price,
      key: 'priceTo',
      value: query.priceTo,
      longKey: 'priceTo',
    });
  }

  if (query.distanceCenterTo) {
    activeFiltersWithTextAndKey.push({
      ...umbracoFiltersText.center,
      key: 'distanceCenterTo',
      value: query.distanceCenterTo,
      longKey: 'distanceCenterTo',
    });
  }

  if (query.distanceBeachTo) {
    activeFiltersWithTextAndKey.push({
      ...umbracoFiltersText.beach,
      key: 'distanceBeachTo',
      value: query.distanceBeachTo,
      longKey: 'distanceBeachTo',
    });
  }

  if (query.maxTransfer) {
    activeFiltersWithTextAndKey.push({
      ...umbracoFiltersText.transfer,
      key: 'maxTransfer',
      value: query.maxTransfer,
      longKey: 'maxTransfer',
    });
  }

  filters.forEach((filterKey) => {
    const longKey = shortKeyTranslation[filterKey];
    if (filterKey === 'r2' || filterKey === 'r3' || filterKey === 'r4' || filterKey === 'r5') {
      activeFiltersWithTextAndKey.push({
        ...umbracoFiltersText.rating,
        key: filterKey,
        longKey,
        value: filterKey.substr(1, 1),
      });
    } else {
      const filterObject = umbracoFiltersText[longKey] || {
        name: geographicalDictionary?.[filterKey]?.description,
      };

      if (!filterObject.name) {
        return;
      }

      activeFiltersWithTextAndKey.push({
        ...filterObject,
        key: filterKey,
        longKey,
      });
    }
  });

  return activeFiltersWithTextAndKey;
};
