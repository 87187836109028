import React, { useState } from 'react';
import styled from 'styled-components';
import { transitions } from '@bwoty-web/styleguide/variables/transitions';
import { Button, HotelHitLoader } from '@bwoty-web/styleguide';
import HotelContainer from './HotelContainer';
import MapContainer from './map/MapContainer';
import PriceDetailsPopup from './details/PriceDetailsPopup';
import { useInitialState } from '../hooks/useInitialState';
import { useLocationContext } from '../context/Location';
import { useHotelsContext } from '../context/Hotels';

const HotelLoaders = styled.div`
  margin-top: 20px;
`;

const ListContent = styled.div`
  transition: opacity ${transitions.default};
`;

const ListFooter = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// shouldClientFetchHotels was removed, should probably be put back and implemented for SSR SEO reasons.

const HotelListContainer = () => {
  const [priceDetailsHotel, setPriceDetailsHotel] = useState(null);
  const location = useLocationContext();
  const hotelsResult = useHotelsContext();

  const {
    hotels,
    hotelIds,
    hasHotels,
    hasAll,
    isLoadingInitialHotels,
    isLoadingMoreHotels,
    isLoadingRefetchHotels,
    fetchAllHotels,
    fetchMoreHotels,
  } = hotelsResult;

  const {
    umbracoTexts: { labels: texts },
  } = useInitialState();

  const isMapView = location.searchParams.view === 'map';

  const shouldRenderLoading =
    !isMapView && (isLoadingMoreHotels || isLoadingInitialHotels || isLoadingRefetchHotels);

  return (
    <>
      <MapContainer
        hotels={hotels}
        hotelIds={hotelIds}
        hasAll={hasAll}
        fetchAllHotels={fetchAllHotels}
        isLoadingHotels={isLoadingInitialHotels}
      />
      {!isMapView && !isLoadingInitialHotels && !isLoadingRefetchHotels && hasHotels && (
        <ListContent data-test="hf-hotel-list">
          {hotelIds.map((id, i) => (
            <HotelContainer
              key={`${id}_${i}`}
              id={id}
              hotelObject={hotels[id]}
              position={i + 1}
              openPriceDetails={setPriceDetailsHotel}
              location={location}
            />
          ))}
        </ListContent>
      )}
      {shouldRenderLoading && (
        <HotelLoaders>
          <HotelHitLoader />
        </HotelLoaders>
      )}
      {!hasAll && !isMapView && !isLoadingMoreHotels && !isLoadingInitialHotels && (
        <ListFooter>
          <Button
            theme="primary"
            loading={isLoadingMoreHotels}
            onClick={fetchMoreHotels}
            data-test="hf-showmore"
          >
            {texts?.loadMore}
          </Button>
        </ListFooter>
      )}
      <PriceDetailsPopup
        hotelObject={priceDetailsHotel}
        clearHotel={() => setPriceDetailsHotel(null)}
      />
    </>
  );
};

export default HotelListContainer;
