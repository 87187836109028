import React from 'react';
import { HotelHit, HotelHitMap } from '@bwoty-web/styleguide';
import formatPrice from '@bwoty-web/price-formatter';
import { deviceWidths } from '@bwoty-web/styleguide/variables/widths';
import { HotelHitWrap } from './styles';
import { toSiteId } from '../../../server/util/idToMucd';
import { getDeviceSources } from '../../utils/imageUtils';

const getIncludedAncillary = (texts, mealAncillary) => {
  if (!Array.isArray(texts?.mealAncillary)) {
    return {
      mealAncillary: null,
      mealAncillaryExtension: null,
    };
  }
  const included = texts?.mealAncillary.find((caAncillary) => caAncillary?.value === mealAncillary);
  if (included && included.name && texts.mealAncillaryExtension) {
    return {
      mealAncillary: included.name,
      mealAncillaryExtension: `${texts.mealAncillaryExtension}.`,
    };
  }
  return {
    mealAncillary: null,
    mealAncillaryExtension: null,
  };
};

const HotelHitOrigo = ({
  origoData,
  texts,
  mucd,
  isMapView,
  hotelUrl,
  onSelect,
  onClose,
  openPriceDetails,
  openGallery,
  paxLabel,
  imageDomain,
}) => {
  if (!origoData || !texts) {
    return null;
  }
  const {
    content: origoContent,
    mainCategory,
    lolloAndBernie,
    accommodations,
    priceOffer,
  } = origoData;

  const tags = [];
  if (origoContent?.productInfo?.isAdultHotel && texts?.webuiHotelHit?.adultFriendly) {
    tags.push({ text: texts?.webuiHotelHit?.adultFriendly, theme: 'adult' });
  } else if (origoContent?.productInfo?.isFamilyHotel && texts?.webuiHotelHit?.childFriendly) {
    tags.push({ text: texts?.webuiHotelHit?.childFriendly, theme: 'family' });
  }

  const conceptName = (mainCategory?.name || '').toLowerCase().replace(/(\s|\.|')/g, '');
  const conceptRowText = conceptName ? texts?.webuiHotelHit?.[`concept_${conceptName}`] : '';
  const roundUp = true;

  const relativeUrl = origoContent?.listImage?.relativeUrl ?? '';

  const mobileImageSize = { width: '390', height: '240' };
  const desktopImageSize = { width: '366', height: '366' };

  const deviceSpecs = [
    {
      size: mobileImageSize,
      mediaString: `(max-width: ${deviceWidths.tabletMax})`,
    },
    {
      size: desktopImageSize,
      mediaString: `(min-width: ${deviceWidths.tablet})`,
    },
  ];

  const sources = getDeviceSources(deviceSpecs, imageDomain, relativeUrl, null, null, 2);

  const imageContent = {
    picture: {
      alt: origoContent?.name,
      sources,
    },
    concept: {
      name: conceptName,
      usp: {
        header: mainCategory?.usp?.header,
        list: mainCategory?.usp?.items,
      },
    },
    tags,
    lolloAndBernie:
      lolloAndBernie !== null
        ? {
            name: lolloAndBernie?.name,
            imageUrl: lolloAndBernie?.images[0]?.url,
          }
        : null,
    showGallery: texts?.webuiHotelHit?.showGallery,
  };

  const includedAncillary = getIncludedAncillary(
    texts?.webuiHotelHit,
    origoContent?.productInfo?.mealAncillary
  );

  const packageDescriptionText =
    accommodations?.[0].rooms?.[0].boardCode !== 'N'
      ? accommodations?.[0].rooms?.[0].boardCodeDescription
      : null;

  const siteId = toSiteId(mucd);

  const mapUspList = (quickFacts) => {
    return quickFacts?.map((quickFact) => {
      return {
        id: quickFact.id,
        name: quickFact.title,
        value: quickFact.displayValue,
      };
    });
  };

  const getPackageInfoText = (content, packageTexts) => {
    const packageInfo = packageTexts?.packageInfoNewPriceCommunication || packageTexts?.packageInfo || '';
    const flightOnlyInfo =
    packageTexts?.flightOnlyInfoNewPriceCommunication || packageTexts?.flightOnlyInfo || '';
    const unSpecifiedInfo =
    packageTexts?.unSpecifiedInfoNewPriceCommunication || packageTexts?.unSpecifiedInfo || '';

    if (packageInfo || content?.isUnspecified) {
      if (!content?.isFlightOnly && !content?.isUnspecified) {
        return packageInfo;
      }

      if (content?.isFlightOnly && !content?.isUnspecified) {
        return flightOnlyInfo;
      }

      if (content?.isUnspecified) {
        return unSpecifiedInfo;
      }
    }

    return null;
  };

  const content = {
    conceptRowText,
    headerContent: {
      titleText: origoContent?.name,
      geographical: origoContent?.geographical,
      link: {
        url: hotelUrl || null,
        target: '_blank',
      },
      rating: origoContent?.doubledRating,
      showEsg: origoContent?.productInfo?.isEcoCertified,
    },
    bodyContent: {
      uspList: mapUspList(origoContent?.hotelListQuickFacts),
      guestRating: origoContent?.guestRating || null,
      tripAdvisor: {
        image: origoContent?.tripAdvisor?.ratingImage,
        rating: origoContent?.tripAdvisor?.averageRating,
      },
      texts: {
        guestRatingText: texts?.webuiHotelHit?.guestRating,
        tripAdvisorText: texts?.webuiHotelHit?.tripAdvisor,
        mealsTexts: {
          packageDescriptionText,
          packageIncludedText: texts?.webuiHotelHit?.boardCodeExtension,
          ancillaryText: includedAncillary?.mealAncillary,
          ancillaryExtensionText: includedAncillary?.mealAncillaryExtension,
        },
      },
    },
    footerContent: {
      texts: {
        buttonText: texts?.webuiHotelHit?.button,
        priceDetailsText: texts?.webuiHotelHit?.details,
        packageInfoText: getPackageInfoText(origoContent, texts?.webuiHotelHit),
        perPersonText: texts?.webuiHotelHit?.valuePP,
        paxText: paxLabel,
        noPriceText: texts?.webuiHotelHit?.noPriceLabel,
        discountText: texts?.discount,
      },
      prices: {
        price:
          siteId && priceOffer?.totalPrice && priceOffer?.totalPrice !== 0
            ? formatPrice(priceOffer?.totalPrice, siteId, roundUp)
            : null,
        originalPrice:
          siteId &&
          priceOffer?.ordinaryPrice &&
          priceOffer?.ordinaryPrice !== 0 &&
          priceOffer?.ordinaryPrice !== priceOffer?.totalPrice
            ? formatPrice(priceOffer?.ordinaryPrice, siteId, roundUp)
            : null,
        pricePerPerson:
          siteId && priceOffer?.adultPrice && priceOffer?.adultPrice !== 0
            ? formatPrice(priceOffer?.adultPrice, siteId, roundUp)
            : null,
      },
    },
  };

  const HotelHitComponent = isMapView ? (
    <HotelHitMap
      href={hotelUrl}
      onSelect={onSelect || null}
      onClose={onClose}
      imageContent={imageContent}
      content={content}
      siteId={siteId}
    />
  ) : (
    <HotelHitWrap>
      <HotelHit
        href={hotelUrl}
        onSelect={onSelect || null}
        onOpenPriceDetails={openPriceDetails} 
        onOpenGallery={openGallery}
        onClose={onClose}
        imageContent={imageContent}
        content={content}
        siteId={siteId}
        lazy
      />
    </HotelHitWrap>
  );

  return HotelHitComponent;
};

export default HotelHitOrigo;
